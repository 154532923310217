import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Select,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Responsive, WidthProvider } from "react-grid-layout";

import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { traer_color } from "../../colores/Colores";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { red } from "@mui/material/colors";

import EstilosContext from "../../contextos/EstilosContext";
import ObraContext from "../../contextos/ObraContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import MensajesContext from "../../contextos/MensajesContext";
import AlertValidConfig from "../../components/AlertValidConfig";
import CardDePlano from "../controlDeAvance/CardDePlano";
import ServidorContext from "../../contextos/ServidorContext";
import ControlDeAvanceContext from "../../contextos/ControlDeAvanceContext";
import FiltroTiposDePlanos from "../controlDeAvance/PageDashboardControlDeAvance/FiltroTiposDePlanos";
import TablaDeTareas from "../presupuestosBTP/TablaDeTareas";

const ResponsiveGridLayout = WidthProvider(Responsive);

const filter = createFilterOptions();

const PageDashboardControlDeAvance = ({ setTitulo, setPagina }) => {
  let navigate = useNavigate();

  const [color, setColor] = useState("");
  const { servidor } = useContext(ServidorContext);
  const { screenWidth } = useContext(EstilosContext);
  const { usuario } = useContext(UsuarioContext);
  const { obra } = useContext(ObraContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const { planosDeObra, setPlanosDeObra, filtro, setFiltro } = useContext(
    ControlDeAvanceContext
  );

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogLocales, setOpenDialogLocales] = useState(false);
  const [openDialogTarea, setOpenDialogTarea] = useState(false);
  const [plano, setPlano] = useState("");
  const [imagen, setImagen] = useState("");
  const [listaDePlanos, setListaDePlanos] = useState([]);
  const [listaDeTareas, setListaDeTareas] = useState([]);

  const [planosFiltrados, setPlanosFiltrados] = useState([]);

  // ******************************

  const [tiposDePlanos, setTiposDePlanos] = useState("");
  const [comboTiposDePlanos, setComboTiposDePlanos] = useState([]);
  const [comboLocales, setComboLocales] = useState([]);
  const [comboTareas, setComboTareas] = useState([]);

  const [tipoDePlanoSelected, setTipoDePlanoSelected] = useState("");
  const [localesSelected, setLocalesSelected] = useState("");
  const [tareasSelected, setTareasSelected] = useState("");

  const [value, setValue] = useState({ IDTipoDePlano: "", TipoDePlano: "" });
  const [valueLocales, setValueLocales] = useState({ IDLocal: "", Local: "" });
  const [valueTareas, setValueTareas] = useState({ IDTarea: "", Tarea: "" });
  const [open, toggleOpen] = useState(false);
  const [openLocales, toggleOpenLocales] = useState(false);
  const [openTareas, toggleOpenTareas] = useState(false);

  const [tareasAsignadas, setTareasAsignadas] = useState([]);
  const [tareaIngresada, setTareaIngresada] = useState("");

  const [dialogValue, setDialogValue] = useState({
    IDTipoDePlano: "",
    TipoDePlano: "",
  });

  const [dialogValueLocales, setDialogValueLocales] = useState({
    IDLocal: "",
    Local: "",
  });

  const [dialogValueTareas, setDialogValueTareas] = useState({
    IDTarea: "",
    Tarea: "",
  });

  const initValid = { tipoDePlanoSelectedValid: false };
  const [validForm, setValidForm] = useState(initValid);

  const initValidLocales = { localesSelectedValid: false };
  const [validFormLocales, setValidFormLocales] = useState(initValidLocales);

  const initValidTareas = { tareasSelectedValid: false };
  const [validFormTareas, setValidFormTareas] = useState(initValidTareas);

  // ******************************

  // const [tipoDePlano, setTipoDePlano] = useState("")

  useEffect(() => {
    traer_tipos_de_planos();
    traer_locales();
    traer_tareas();
  }, []);

  useEffect(() => {
    setTitulo("Dashboard Control de Avance");
    setPagina("");

    // console.log("A");
  }, [setTitulo]);

  useEffect(() => {
    setPlanosDeObra(listaDePlanos);
    // console.log("listaDePlanos: ", listaDePlanos);
  }, [listaDePlanos]);

  // useEffect(() => {
  //   console.log("planosFiltrados NEW: ", planosFiltrados);
  // }, [planosFiltrados]);

  useEffect(() => {
    // console.log("lista: ", listaDePlanos);
    let idsFiltro = filtro.map((f) => f.IDTipoDePlano);

    // console.log("idsFiltro: ", idsFiltro);

    let listaFiltrada = listaDePlanos.filter((p) =>
      idsFiltro.includes(p.IDTipoDePlano)
    );

    // console.log("listaFiltrada: ", listaFiltrada);

    setPlanosFiltrados(listaFiltrada);
  }, [filtro]);

  // useEffect(() => {
  //   traer_tareas_asignadas();
  // }, [dialogValueLocales, tareaIngresada]);

  const guardar_tipo_de_plano = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log({
    //   operacion: "GUARDAR_TIPO_DE_PLANO",
    //   idObra: obra.IDObra,
    //   tipoDePlano: dialogValue.TipoDePlano,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_TIPO_DE_PLANO",
        idObra: obra.IDObra,
        tipoDePlano: dialogValue.TipoDePlano,
      }),
    });
    const respuesta = await datos.json();

    if (respuesta.result.resultado === "OK") {
      setDialogValue({
        IDTipoDePlano: respuesta.result.tipoDePlano[0]["IDTipoDePlano"],
        TipoDePlano: respuesta.result.tipoDePlano[0]["TipoDePlano"],
      });

      // setMensaje({ text: "Grupo agregado con éxito!", tipo: "success" });

      // setMostrarMensaje(true);
    } else {
      console.log("NO TRAE NADA EN guardar_tipo_de_plano()");
      // setState([]);
    }
  };

  const traer_tipos_de_planos = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_TIPOS_DE_PLANOS",
        idObra: obra.IDObra,
      }),
    });
    const respuesta = await datos.json();

    // console.log("listaTiposDePlanos: ", respuesta.result.listaTiposDePlanos);

    if (respuesta.result.resultado === "OK") {
      setComboTiposDePlanos(respuesta.result.listaTiposDePlanos);
    } else {
      setComboTiposDePlanos([]);
      console.log("NO TRAE NADA EN traer_tipos_de_planos()");
      // setState([]);
    }
  };

  const guardar_local = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log({
    //   operacion: "GUARDAR_TIPO_DE_PLANO",
    //   idObra: obra.IDObra,
    //   tipoDePlano: dialogValue.TipoDePlano,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_LOCAL",
        idObra: obra.IDObra,
        local: dialogValueLocales.Local,
      }),
    });
    const respuesta = await datos.json();

    if (respuesta.result.resultado === "OK") {
      setDialogValueLocales({
        IDLocal: respuesta.result.local[0]["IDLocal"],
        Local: respuesta.result.local[0]["Local"],
      });

      // setMensaje({ text: "Grupo agregado con éxito!", tipo: "success" });

      // setMostrarMensaje(true);
    } else {
      console.log("NO TRAE NADA EN guardar_local()");
      // setState([]);
    }
  };

  const traer_locales = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_LOCALES", // LOS LOCALES ESTAN EN LA TABLA GRUPOS
        idObra: obra.IDObra,
      }),
    });
    const respuesta = await datos.json();

    // console.log("listaTiposDePlanos: ", respuesta.result.listaTiposDePlanos);

    if (respuesta.result.resultado === "OK") {
      // console.log("listaLocales: ", respuesta.result.listaLocales);
      setComboLocales(respuesta.result.listaLocales);
    } else {
      setComboLocales([]);
      console.log("NO TRAE NADA EN traer_locales()");
      // setState([]);
    }
  };

  const traer_tareas = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_TAREAS",
        idObra: obra.IDObra,
      }),
    });
    const respuesta = await datos.json();

    // console.log("listaTiposDePlanos: ", respuesta.result.listaTiposDePlanos);

    if (respuesta.result.resultado === "OK") {
      // console.log("listaLocales: ", respuesta.result.listaLocales);
      setComboTareas(respuesta.result.listaTareas);
    } else {
      setComboTareas([]);
      console.log("NO TRAE NADA EN traer_tareas()");
      // setState([]);
    }
  };

  const traer_tareas_asignadas = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_TAREAS_ASIGNADAS",
        idObra: obra.IDObra,
        idLocal: dialogValueLocales.IDLocal,
        tareaIngresada: tareaIngresada,
      }),
    });
    const respuesta = await datos.json();

    // console.log("listaTiposDePlanos: ", respuesta.result.listaTiposDePlanos);

    if (respuesta.result.resultado === "OK") {
      // console.log("listaLocales: ", respuesta.result.listaLocales);
      setTareasAsignadas(respuesta.result.listaTareasAsignadas);
    } else {
      setTareasAsignadas([]);
      console.log("NO TRAE NADA EN traer_tareas_asignadas()");
      // setState([]);
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(listaDePlanos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setListaDePlanos(items);
    setPlanosFiltrados(items);
  };

  const handleImageUpload = async (event) => {
    event.preventDefault();

    // if (
    //   comboTiposDePlanos.filter(
    //     (item) => item.IDTipoDePlano === dialogValue.IDTipoDePlano
    //   ).length === 0
    // ) {

    if (dialogValue.TipoDePlano !== "") {
      if (plano !== "") {
        if (plano.length > 3) {
          // console.log("value: ", value);
          // console.log("value === null: ", value === null);
          // if (value === null) {
          if (value.IDTipoDePlano === "" || value === null) {
            // console.log("GUARDA");
            guardar_tipo_de_plano();
          } else {
            // console.log("NO GUARDA");
          }

          setOpenDialog(false);
          setTimeout(async () => {
            const file = await new Promise((resolve) => {
              const fileInput = document.createElement("input");
              fileInput.type = "file";
              fileInput.addEventListener("change", (event) =>
                resolve(event.target.files[0])
              );
              fileInput.click();
            });

            if (file) {
              const reader = new FileReader();
              reader.onloadend = () => {
                setSelectedFiles([
                  ...selectedFiles,
                  {
                    url: reader.result,
                    plano: plano,
                    idTipoPlano: dialogValue.IDTipoDePlano,
                  },
                ]);
                setImagen(reader.result);
              };
              reader.readAsDataURL(file);

              // agregar_plano();
            }
          }, 500);
        } else {
          setMensaje({
            text: "El nombre del plano es muy corto.",
            tipo: "error",
          });

          setMostrarMensaje(true);
        }
      } else {
        setMensaje({
          text: "El nombre del plano no puede ser vacío.",
          tipo: "error",
        });

        setMostrarMensaje(true);
      }
    } else {
      setMensaje({ text: "Seleccione el tipo de plano.", tipo: "error" });

      setMostrarMensaje(true);
    }

    // } else {
    //   setMensaje({ text: "El tipo de plano ya existe.", tipo: "error" });

    //   setMostrarMensaje(true);
    // }

    // asignar_grupo();

    // setValue({
    //   IDGrupo: parseInt(dialogValue.IDGrupo, 10),
    //   Grupo: dialogValue.Grupo,
    // });

    // handleClose();
  };

  const handleConfirmarLocal = async (event) => {
    event.preventDefault();

    // if (
    //   comboTiposDePlanos.filter(
    //     (item) => item.IDTipoDePlano === dialogValue.IDTipoDePlano
    //   ).length === 0
    // ) {

    if (dialogValueLocales.Local !== "") {
      if (dialogValueLocales.Local.length > 3) {
        // console.log("value: ", value);
        // console.log("value === null: ", value === null);
        // if (value === null) {
        if (valueLocales.IDLocal === "" || valueLocales === null) {
          // console.log("GUARDA");
          guardar_local();
        } else {
          // console.log("NO GUARDA");
        }

        setOpenDialogLocales(false);

        // setTimeout(async () => {
        //   const file = await new Promise((resolve) => {
        //     const fileInput = document.createElement("input");
        //     fileInput.type = "file";
        //     fileInput.addEventListener("change", (event) =>
        //       resolve(event.target.files[0])
        //     );
        //     fileInput.click();
        //   });

        //   if (file) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //       setSelectedFiles([
        //         ...selectedFiles,
        //         {
        //           url: reader.result,
        //           plano: plano,
        //           idTipoPlano: dialogValue.IDTipoDePlano,
        //         },
        //       ]);
        //       setImagen(reader.result);
        //     };
        //     reader.readAsDataURL(file);

        //     // agregar_plano();
        //   }
        // }, 500);
      } else {
        setMensaje({
          text: "El nombre del local es muy corto.",
          tipo: "error",
        });

        setMostrarMensaje(true);
      }
    } else {
      setMensaje({ text: "Seleccione el local.", tipo: "error" });

      setMostrarMensaje(true);
    }

    // } else {
    //   setMensaje({ text: "El tipo de plano ya existe.", tipo: "error" });

    //   setMostrarMensaje(true);
    // }

    // asignar_grupo();

    // setValue({
    //   IDGrupo: parseInt(dialogValue.IDGrupo, 10),
    //   Grupo: dialogValue.Grupo,
    // });

    // handleClose();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // const handleOpenDialogLocales = () => {
  //   setOpenDialogLocales(true);
  // };

  const handleOpenDialogTareas = () => {
    setOpenDialogTarea(true);
  };

  const handleCloseDialog = () => {
    setDialogValue({
      IDTipoDePlano: "",
      TipoDePlano: "",
    });
    toggleOpen(false);

    setOpenDialog(false);
  };

  const handleCloseDialogLocales = () => {
    setDialogValueLocales({
      IDLocal: "",
      Local: "",
    });
    toggleOpenLocales(false);

    setOpenDialogLocales(false);
  };

  const handleCloseDialogTareas = () => {
    setDialogValueTareas({
      IDTarea: "",
      Tarea: "",
    });
    toggleOpenTareas(false);

    setOpenDialogTarea(false);
  };

  const handleOpenABMTareas = () => {
    navigate("/listaTareas");
  };

  const handleOpenABMLocales = () => {
    navigate("/locales");
  };

  useEffect(() => {
    const primaryColor = traer_color("blue", 500);
    const secondaryColor = traer_color("blue", 700);

    setColor({ primary: primaryColor, secondary: secondaryColor });
  }, []);

  useEffect(() => {
    if (plano !== "" && imagen !== "") {
      agregar_plano();
    }
  }, [selectedFiles]);

  useEffect(() => {
    // if (filtro.length > 0) {
    //   console.log("listaDePlanos: ", listaDePlanos);
    //   // console.log("filtro: ", filtro);

    //   let idsFiltro = filtro.map((f) => f.IDTipoDePlano);
    //   let listaFiltrada = listaDePlanos.filter((p) =>
    //     idsFiltro.includes(p.IDTipoDePlano)
    //   );

    //   setPlanosFiltrados(listaFiltrada);
    // } else {
    traer_planos();
    // }
    // console.log("filtro: ", filtro);
    // console.log("planosFiltrados: ", planosFiltrados);
  }, []);

  const traer_planos = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log("plano: ", plano);
    // console.log("imagen: ", imagen);
    // console.log("idUsuario: ", usuario.IDUsuario);
    // console.log("idObra: ", obra.IDObra);

    let inicio = new Date();
    console.log("INI: ", inicio);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_PLANOS_EN_CONTROL_DE_AVANCE",
        idObra: obra.IDObra,
        filtros: "",
      }),
    });
    const respuesta = await datos.json();

    let fin = new Date();
    console.log("FIN: ", fin);

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      // setMensaje({ text: "Plano agregado con éxito!", tipo: "success" });
      // setMostrarMensaje(true);

      setListaDePlanos(respuesta.result.listaDePlanos);

      if (filtro.length > 0) {
        let idsFiltro = filtro.map((f) => f.IDTipoDePlano);
        let listaFiltrada = respuesta.result.listaDePlanos.filter((p) =>
          idsFiltro.includes(p.IDTipoDePlano)
        );
        setPlanosFiltrados(listaFiltrada);
      } else {
        setPlanosFiltrados(respuesta.result.listaDePlanos);
      }
    } else {
      console.log("NO TRAE NADA EN traer_planos()");
      // setState([]);
    }
  };

  const agregar_plano = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log("plano: ", plano);
    // console.log("imagen: ", imagen);
    // console.log("idUsuario: ", usuario.IDUsuario);
    // console.log("idObra: ", obra.IDObra);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "AGREGAR_PLANO_EN_CONTROL_DE_AVANCE",
        plano: plano,
        imagen: imagen,
        idUsuario: usuario.IDUsuario,
        idObra: obra.IDObra,
        idTipoDePlano: dialogValue.IDTipoDePlano,
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      setListaDePlanos(respuesta.result.listaDePlanos);
      setPlanosFiltrados(respuesta.result.listaDePlanos);

      setMensaje({ text: "Plano agregado con éxito!", tipo: "success" });
      setMostrarMensaje(true);
    } else {
      console.log("NO TRAE NADA EN agregar_plano()");
      // setState([]);
    }
  };

  const handleAgregarTarea = async () => {};

  // const layouts = listaDePlanos.map((item, i) => ({
  //   i: item.IDPlano,
  //   x: (i % 4) * 3,
  //   y: Math.floor(i / 4) * 3,
  //   w: 3,
  //   h: 3,
  // }));

  return (
    <div>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{"Agregar Plano"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor complete el formulario.
          </DialogContentText>

          <Autocomplete
            id="free-solo-dialog-demo"
            options={comboTiposDePlanos}
            value={value}
            // inputValue={value === null ? "" : value.Tarea}
            clearOnBlur={false}
            selectOnFocus
            handleHomeEndKeys
            freeSolo
            // disabled={!camposDisponibles.tarea}
            onChange={(event, newValue) => {
              // console.log("newValue: ", newValue);

              if (typeof newValue === "string") {
                // console.log("PASA POR 1");

                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  toggleOpen(true);

                  setDialogValue({
                    IDTipoDePlano: "",
                    TipoDePlano: newValue,
                  });

                  // setTiposDePlanos({
                  //   ...tiposDePlanos,
                  //   IDTipoDePlano: "",
                  //   TipoDePlano: newValue,
                  // });
                });
              } else if (newValue && newValue.inputValue) {
                // console.log("PASA POR 2: Cuando agrega un tipo de plano.");

                toggleOpen(true);
                setDialogValue({
                  IDTipoDePlano: "",
                  TipoDePlano: newValue.inputValue,
                });

                // console.log("newValue.inputValue: ", newValue.inputValue);

                // setTiposDePlanos({
                //   ...tiposDePlanos,
                //   IDTipoDePlano: "",
                //   TipoDePlano: newValue.inputValue,
                // });
              } else {
                // console.log(
                //   "PASA POR 3: Cuando selecciona un tipo de plano existente."
                // );
                setValue(newValue);

                if (newValue !== null) {
                  setDialogValue({
                    IDTipoDePlano: newValue.IDTipoDePlano,
                    TipoDePlano: newValue.TipoDePlano,
                  });

                  // setTiposDePlanos({
                  //   ...tiposDePlanos,
                  //   IDTipoDePlano: newValue.IDTipoDePlano,
                  //   TipoDePlano: newValue.TipoDePlano,
                  // });
                } else {
                  setDialogValue({
                    IDTipoDePlano: "",
                    TipoDePlano: "",
                  });

                  // setTiposDePlanos({
                  //   ...tiposDePlanos,
                  //   IDTipoDePlano: "",
                  //   TipoDePlano: "",
                  // });
                }
              }

              // setLista([...lista, newValue]);

              if (newValue === null) {
                setTipoDePlanoSelected({ IDTipoDePlano: "", TipoDePlano: "" });
              } else {
                setTipoDePlanoSelected(newValue);
              }
              // alert(JSON.stringify(newValue));

              setValidForm({ ...validForm, grupoValid: true });
              // setCamposDisponibles({ ...camposDisponibles, unidad: true });
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              // console.log("options: ", options);
              // console.log("params: ", params);

              if (
                params.inputValue !== "" &&
                !options.some(
                  (item) =>
                    item.TipoDePlano.toLowerCase() ===
                    params.inputValue.toLowerCase()
                )
              ) {
                filtered.push({
                  inputValue: params.inputValue,
                  TipoDePlano: `Agregar "${params.inputValue}"`,
                });
              }
              // console.log("filtered: ", filtered);
              return filtered;
            }}
            getOptionLabel={(option) => {
              // console.log("option: ", option);
              // e.g value selected with enter, right from the input
              if (typeof option === "string") {
                // console.log("PASA POR A");
                return option;
              }
              if (option && option.inputValue) {
                // console.log("PASA POR B");
                return option.inputValue;
              }
              if (option && option.TipoDePlano) {
                // console.log("PASA POR C");

                return option.TipoDePlano;
              }
              return "";
              // if (typeof option === "string") {
              //   return option;
              // }
              // if (option.inputValue) {
              //   return option.inputValue;
              // }
              // console.log("option.inputValue: ", option.inputValue);
              // return option.Tarea;
            }}
            // clearOnBlur

            renderOption={(props, option) => (
              <li {...props} key={option.IDTipoDePlano}>
                {option.TipoDePlano}
              </li>
            )}
            // sx={{ width: 300 }}

            renderInput={(params) => (
              <TextField {...params} label="Tipo de plano" variant="standard" />
            )}
          />

          <br />

          <TextField
            autoFocus
            variant="standard"
            margin="dense"
            id="name"
            label="Piso"
            type="text"
            fullWidth
            value={plano}
            onChange={(e) => setPlano(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleImageUpload} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogLocales} onClose={handleCloseDialogLocales}>
        <DialogTitle>{"LOCALES"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Agregue / Edite las tareas de un local.
          </DialogContentText>
          <br />
          <Autocomplete
            id="free-solo-dialog-demo"
            options={comboLocales}
            value={valueLocales}
            // inputValue={value === null ? "" : value.Tarea}
            clearOnBlur={false}
            selectOnFocus
            handleHomeEndKeys
            freeSolo
            // disabled={!camposDisponibles.tarea}
            onChange={(event, newValue) => {
              // console.log("newValue: ", newValue);

              if (typeof newValue === "string") {
                // console.log("PASA POR 1");

                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  toggleOpenLocales(true);

                  setDialogValueLocales({
                    IDLocal: "",
                    Local: newValue,
                  });

                  // setTiposDePlanos({
                  //   ...tiposDePlanos,
                  //   IDTipoDePlano: "",
                  //   TipoDePlano: newValue,
                  // });
                });
              } else if (newValue && newValue.inputValue) {
                // console.log("PASA POR 2: Cuando agrega un tipo de plano.");

                toggleOpenLocales(true);
                setDialogValueLocales({
                  IDLocal: "",
                  Local: newValue.inputValue,
                });

                // console.log("newValue.inputValue: ", newValue.inputValue);

                // setTiposDePlanos({
                //   ...tiposDePlanos,
                //   IDTipoDePlano: "",
                //   TipoDePlano: newValue.inputValue,
                // });
              } else {
                // console.log(
                //   "PASA POR 3: Cuando selecciona un tipo de plano existente."
                // );
                setValueLocales(newValue);

                if (newValue !== null) {
                  setDialogValueLocales({
                    IDLocal: newValue.IDLocal,
                    Local: newValue.Local,
                  });

                  // setTiposDePlanos({
                  //   ...tiposDePlanos,
                  //   IDTipoDePlano: newValue.IDTipoDePlano,
                  //   TipoDePlano: newValue.TipoDePlano,
                  // });
                } else {
                  setDialogValueLocales({
                    IDLocal: "",
                    Local: "",
                  });

                  // setTiposDePlanos({
                  //   ...tiposDePlanos,
                  //   IDTipoDePlano: "",
                  //   TipoDePlano: "",
                  // });
                }
              }

              // setLista([...lista, newValue]);

              if (newValue === null) {
                setLocalesSelected({ IDLocal: "", Local: "" });
              } else {
                setLocalesSelected(newValue);
              }
              // alert(JSON.stringify(newValue));

              setValidFormLocales({ ...validFormLocales, localValid: true });
              // setCamposDisponibles({ ...camposDisponibles, unidad: true });
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              // console.log("options: ", options);
              // console.log("params: ", params);

              if (
                params.inputValue !== "" &&
                !options.some(
                  (item) =>
                    item.Local.toLowerCase() === params.inputValue.toLowerCase()
                )
              ) {
                filtered.push({
                  inputValue: params.inputValue,
                  Local: `Agregar "${params.inputValue}"`,
                });
              }
              // console.log("filtered: ", filtered);
              return filtered;
            }}
            getOptionLabel={(option) => {
              // console.log("option: ", option);
              // e.g value selected with enter, right from the input
              if (typeof option === "string") {
                // console.log("PASA POR A");
                return option;
              }
              if (option && option.inputValue) {
                // console.log("PASA POR B");
                return option.inputValue;
              }
              if (option && option.Local) {
                // console.log("PASA POR C");

                return option.Local;
              }
              return "";
              // if (typeof option === "string") {
              //   return option;
              // }
              // if (option.inputValue) {
              //   return option.inputValue;
              // }
              // console.log("option.inputValue: ", option.inputValue);
              // return option.Tarea;
            }}
            // clearOnBlur

            renderOption={(props, option) => (
              <li {...props} key={option.IDLocal}>
                {option.Local}
              </li>
            )}
            // sx={{ width: 300 }}

            renderInput={(params) => (
              <TextField {...params} label="Local" variant="standard" />
            )}
          />

          <br />

          <Autocomplete
            id="free-solo-dialog-demo"
            options={comboTareas}
            value={valueTareas}
            // inputValue={value === null ? "" : value.Tarea}
            clearOnBlur={false}
            selectOnFocus
            handleHomeEndKeys
            freeSolo
            // disabled={!camposDisponibles.tarea}
            onChange={(event, newValue) => {
              // console.log("newValue: ", newValue);

              if (typeof newValue === "string") {
                // console.log("PASA POR 1");

                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  toggleOpenTareas(true);

                  setDialogValueTareas({
                    IDTarea: "",
                    Tarea: newValue,
                  });

                  // setTiposDePlanos({
                  //   ...tiposDePlanos,
                  //   IDTipoDePlano: "",
                  //   TipoDePlano: newValue,
                  // });
                });
              } else if (newValue && newValue.inputValue) {
                // console.log("PASA POR 2: Cuando agrega un tipo de plano.");

                toggleOpenTareas(true);
                setDialogValueTareas({
                  IDTarea: "",
                  Tarea: newValue.inputValue,
                });

                // console.log("newValue.inputValue: ", newValue.inputValue);

                // setTiposDePlanos({
                //   ...tiposDePlanos,
                //   IDTipoDePlano: "",
                //   TipoDePlano: newValue.inputValue,
                // });
              } else {
                // console.log(
                //   "PASA POR 3: Cuando selecciona un tipo de plano existente."
                // );
                setValueTareas(newValue);

                if (newValue !== null) {
                  setDialogValueTareas({
                    IDTarea: newValue.IDTarea,
                    Tarea: newValue.Tarea,
                  });

                  // setTiposDePlanos({
                  //   ...tiposDePlanos,
                  //   IDTipoDePlano: newValue.IDTipoDePlano,
                  //   TipoDePlano: newValue.TipoDePlano,
                  // });
                } else {
                  setDialogValueTareas({
                    IDTarea: "",
                    Tarea: "",
                  });

                  // setTiposDePlanos({
                  //   ...tiposDePlanos,
                  //   IDTipoDePlano: "",
                  //   TipoDePlano: "",
                  // });
                }
              }

              // setLista([...lista, newValue]);

              if (newValue === null) {
                setTareasSelected({ IDTarea: "", Tarea: "" });
              } else {
                setTareasSelected(newValue);
              }
              // alert(JSON.stringify(newValue));

              setValidFormTareas({ ...validFormTareas, tareaValid: true });
              // setCamposDisponibles({ ...camposDisponibles, unidad: true });
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              // console.log("options: ", options);
              // console.log("params: ", params);

              if (
                params.inputValue !== "" &&
                !options.some(
                  (item) =>
                    item.Tarea.toLowerCase() === params.inputValue.toLowerCase()
                )
              ) {
                filtered.push({
                  inputValue: params.inputValue,
                  Tarea: `Agregar "${params.inputValue}"`,
                });
              }
              // console.log("filtered: ", filtered);
              return filtered;
            }}
            getOptionLabel={(option) => {
              // console.log("option: ", option);
              // e.g value selected with enter, right from the input
              if (typeof option === "string") {
                // console.log("PASA POR A");
                return option;
              }
              if (option && option.inputValue) {
                // console.log("PASA POR B");
                return option.inputValue;
              }
              if (option && option.Tarea) {
                // console.log("PASA POR C");

                return option.Tarea;
              }
              return "";
              // if (typeof option === "string") {
              //   return option;
              // }
              // if (option.inputValue) {
              //   return option.inputValue;
              // }
              // console.log("option.inputValue: ", option.inputValue);
              // return option.Tarea;
            }}
            // clearOnBlur

            renderOption={(props, option) => (
              <li {...props} key={option.IDTarea}>
                {option.Tarea}
              </li>
            )}
            // sx={{ width: 300 }}

            renderInput={(params) => (
              <TextField {...params} label="Tarea" variant="standard" />
            )}
          />

          <Button variant="text" onClick={() => handleAgregarTarea()}>
            Agregar
          </Button>

          {/* <TextField
            autoFocus
            variant="standard"
            margin="dense"
            id="tarea"
            label="Tarea"
            type="text"
            fullWidth
            value={tareaIngresada}
            onChange={(e) => setTareaIngresada(e.target.value)}
          /> */}

          <br />

          <Paper elevation={3}>
            {tareasAsignadas.map((tarea) => (
              <div key={tarea.IDTarea}>
                {/* <Checkbox
                  id={tarea.IDTarea}
                  key={tarea.IDTarea}
                  icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                  checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                  // checked={asignaciones.some(
                  //   (check) => check.IDDocumentacion == docs.IDDocumentacion
                  // )}

                  checked={seleccionados.some(
                    (item) => item.IDTarea == tarea.IDTarea
                  )}
                  onChange={(event) => handleDesasignarTarea(event, tarea)}
                /> */}
                <DeleteOutlineOutlinedIcon sx={{ color: red[500] }} />

                {tarea.Tarea.toUpperCase()}
              </div>
            ))}

            {/* <Grid container columns={{ xs: 12 }} border={1}>
              <Grid item xs={8} border={0} padding={1}>
                Inodoro colocado
              </Grid>
              <Grid item xs={8} border={0} padding={1}>
                Bidet colocado
              </Grid>
              <Grid item xs={8} border={0} padding={1}>
                Inst. Agua F/C
              </Grid>
            </Grid> */}
          </Paper>

          {/* <TextField
            autoFocus
            variant="standard"
            margin="dense"
            id="name"
            label="Piso"
            type="text"
            fullWidth
            value={plano}
            onChange={(e) => setPlano(e.target.value)}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogLocales}>Cancelar</Button>
          <Button onClick={handleConfirmarLocal} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        {/* <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="planos">
            {(provided) => (
              <Grid
                container
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {listaDePlanos.map((item, index) => (
                  <Draggable
                    key={item.IDPlano}
                    draggableId={item.IDPlano}
                    index={index}
                  >
                    {(provided) => (
                      <Grid
                        item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Card sx={{ width: 345, height: 210, margin: "5px" }}>
                          <CardMedia sx={{ height: 140, padding: "5px" }}>
                            <div
                              style={{
                                width: "340px",
                                height: "130px",
                                overflow: "hidden",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={item.Imagen}
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                alt={`Selected ${index}`}
                              />
                            </div>
                          </CardMedia>
                          <CardActions sx={{ justifyContent: "center" }}>
                            <Button
                              sx={{ fontSize: "25px", width: "100%" }}
                              size="small"
                            >
                              {item.Plano}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext> */}

        <div
          sm={12}
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "#cccccc1f",
            margin: "10px",
            padding: "4px",
            display: "flex",
            justifyContent:
              screenWidth === "xs" || screenWidth === "sm" ? "center" : "left",
            alignItems: "center",
          }}
        >
          <FiltroTiposDePlanos
            // setFiltro={setFiltro}
            // filtro={filtro}
            data={comboTiposDePlanos}
          />
          <Button variant="text" onClick={() => handleOpenABMTareas()}>
            Tareas
          </Button>
          <Button variant="text" onClick={() => handleOpenABMLocales()}>
            Locales
          </Button>

          {/* <Typography variant="h6">
            <Button href={archPDF}>Exportar a Excel</Button>
          </Typography> */}
        </div>

        {Array.isArray(planosFiltrados) &&
          planosFiltrados.map((item, index) => (
            <Grid item key={index}>
              <CardDePlano item={item} />
            </Grid>
          ))}

        <Grid item>
          <Card sx={{ width: 345, height: 210, margin: "5px" }}>
            <CardMedia
              sx={{ padding: "15px" }}
              //   image="./17_piso-1.png"
              //   title="green iguana"
            >
              <Box
                style={{
                  width: 315,
                  height: 180,
                  border: `2px dashed ${color.primary}`,
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                padding={screenWidth === "xs" || screenWidth === "sm" ? 1 : 6}
              >
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // sx={{ border: "1px solid #FF0000" }}
                >
                  <span style={{ marginBottom: "0px", color: color.primary }}>
                    <strong>AGREGAR PLANO:</strong> &nbsp;&nbsp;&nbsp;
                  </span>

                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={handleOpenDialog}
                    >
                      <AddCircleIcon sx={{ fontSize: 40 }} />
                    </IconButton>

                    {/* <IconButton
                      //   color={theme.palette.primary.main}
                      style={{ color: color.secondary }}
                      aria-label="agregar plano"
                      // onClick={() => navigate("/agregarPlano")}
                    >
                      <AddCircleIcon
                        //   style={{ color: theme.palette.secondary.main }}
                        sx={{ fontSize: 40 }}
                      />
                    </IconButton> */}
                  </label>
                </Grid>
              </Box>
            </CardMedia>
            {/* <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            PISO 1
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent> */}
            {/* <CardActions sx={{ justifyContent: "center" }}>
              <Button sx={{ fontSize: "25px", width: "100%" }} size="small">
                PISO 2
              </Button>
            </CardActions> */}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageDashboardControlDeAvance;
