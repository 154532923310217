import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ServidorContext from "../../../contextos/ServidorContext";
import EstilosContext from "../../../contextos/EstilosContext";
import UsuarioContext from "../../../contextos/UsuarioContext";
import ObraContext from "../../../contextos/ObraContext";
import MensajesContext from "../../../contextos/MensajesContext";

const CheckLocalesConTareas = ({
  localesConTareas,
  setLocalElegido,
  localElegido,
}) => {
  const { servidor } = useContext(ServidorContext);
  const { screenWidth } = useContext(EstilosContext);
  const { usuario } = useContext(UsuarioContext);
  const { obra } = useContext(ObraContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [mostrarProgress, setMostrarProgress] = useState(false);

  const seleccionarLocal = (local) => {
    console.log("local: ", local);
    setLocalElegido(local);
  };

  return (
    <Grid container columns={{ xs: 12 }} border={0}>
      {mostrarProgress ? (
        <Grid item xs={12} border={0}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <FormControl sx={{ pl: 1 }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue="female"
            name="radio-buttons-group"
          >
            {localesConTareas.map((local, index) => (
              // <div key={item.IDProveedor}>
              <FormControlLabel
                value={local.IDLocal}
                key={local.IDLocal}
                control={
                  <Radio
                    // checked={index === 0}
                    icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                    checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                    onChange={() => seleccionarLocal(local)}
                  />
                }
                label={
                  localElegido.IDLocal === local.IDLocal ? (
                    <strong>{`${local.Local.toUpperCase()} (${
                      local.ListaTareas.length
                    })`}</strong>
                  ) : (
                    `${local.Local} (${local.ListaTareas.length})`
                  )
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </Grid>
  );
};

export default CheckLocalesConTareas;
