import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ServidorContext from "../../../contextos/ServidorContext";
import EstilosContext from "../../../contextos/EstilosContext";
import UsuarioContext from "../../../contextos/UsuarioContext";
import ObraContext from "../../../contextos/ObraContext";
import MensajesContext from "../../../contextos/MensajesContext";

const CheckTareasDelLocal = ({
  tareasDelLocal,
  setTareasElegidas,
  tareasElegidas,
}) => {
  const { servidor } = useContext(ServidorContext);
  const { screenWidth } = useContext(EstilosContext);
  const { usuario } = useContext(UsuarioContext);
  const { obra } = useContext(ObraContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [localesConTareas, setLocalesConTareas] = useState([]);

  useEffect(() => {
    traer_locales_con_tareas();
  }, []);

  const traer_locales_con_tareas = async () => {
    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_locales.php`;

    // console.log("plano: ", plano);
    // console.log("imagen: ", imagen);
    // console.log("idUsuario: ", usuario.IDUsuario);
    // console.log("idObra: ", obra.IDObra);

    let inicio = new Date();
    console.log("INI: ", inicio);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_LOCALES_CON_TAREAS_DE_TODAS_LAS_OBRAS",
        // idObra: obra.IDObra,
        idUsuario: usuario.IDUsuario,
      }),
    });
    const respuesta = await datos.json();

    let fin = new Date();
    console.log("FIN: ", fin);

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      // setMensaje({ text: "Plano agregado con éxito!", tipo: "success" });
      // setMostrarMensaje(true);

      console.log("listaLocales: ", respuesta.result.listaLocales);

      setLocalesConTareas(respuesta.result.listaLocales);
    } else {
      console.log("NO TRAE NADA EN traer_locales_con_tareas()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const seleccionarTarea = (event, tarea) => {
    console.log("tarea: ", tarea);

    if (event.target.checked) {
      setTareasElegidas([...tareasElegidas, tarea]);
    } else {
      let filtro = tareasElegidas.filter(
        (item) => item.CodTarea !== tarea.CodTarea
      );
      setTareasElegidas(filtro);
    }
  };

  return (
    <Grid container columns={{ xs: 12 }} border={0}>
      {mostrarProgress ? (
        <Grid item xs={12} border={0}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <FormControl sx={{ pl: 1 }}>
          {tareasDelLocal.map((tarea, index) => (
            // <div key={item.IDProveedor}>

            <div key={tarea.CodTarea}>
              <Checkbox
                id={tarea.CodTarea}
                key={tarea.CodTarea}
                icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                checked={tareasElegidas.some(
                  (item) => item.CodTarea === tarea.CodTarea
                )}
                onChange={(event) => seleccionarTarea(event, tarea)}
              />
              ({tarea.CodTarea.toUpperCase()}) {tarea.Tarea.toUpperCase()}
            </div>
          ))}
        </FormControl>
      )}
    </Grid>
  );
};

export default CheckTareasDelLocal;
