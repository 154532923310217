import React, { useContext, useEffect, useState } from "react";
import Encabezado from "./Encabezado";
import { Button, Grid } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CheckObrasConCantidadDeTareas from "./CheckObrasConCantidadDeTareas";
import ObraContext from "../../contextos/ObraContext";
import ServidorContext from "../../contextos/ServidorContext";
import EstilosContext from "../../contextos/EstilosContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import MensajesContext from "../../contextos/MensajesContext";

const PageImportarTareasDeOtraObra = () => {
  const { servidor } = useContext(ServidorContext);
  const { screenWidth } = useContext(EstilosContext);
  const { usuario } = useContext(UsuarioContext);
  const { obra } = useContext(ObraContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [obraElegida, setObraElegida] = useState(false);
  const [mostrarProgress, setMostrarProgress] = useState(false);

  useEffect(() => {
    console.log("obraElegida: ", obraElegida);

    // const llamar_a_importar_tareas = async () => {
    //   await importarTareas();
    // };

    // if (obraElegida) {
    //   llamar_a_importar_tareas();
    // }
  }, [obraElegida]);

  const importarTareas = async () => {
    if (obraElegida) {
      setMostrarProgress(true);

      const API_ENDPOINT = servidor + `api_tareas.php`;

      // console.log("plano: ", plano);
      // console.log("imagen: ", imagen);
      // console.log("idUsuario: ", usuario.IDUsuario);
      // console.log("idObra: ", obra.IDObra);

      let inicio = new Date();
      console.log("INI: ", inicio);

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "IMPORTAR_TAREAS_DE_OTRA_OBRA",
          idObraOrigen: obraElegida.IDObra,
          idObraDestino: obra.IDObra,
        }),
      });
      const respuesta = await datos.json();

      let fin = new Date();
      console.log("FIN: ", fin);

      // console.log("respuesta: ", respuesta);

      if (respuesta.result.resultado === "OK") {
        // setMensaje({ text: "Plano agregado con éxito!", tipo: "success" });
        // setMostrarMensaje(true);

        setMensaje({
          text:
            "Se han importado " +
            respuesta.result.objCantidadesImportadas.tareas +
            " tareas con éxito!",
          tipo: "success",
        });

        setMostrarMensaje(true);

        console.log(
          "objCantidadesImportadas: ",
          respuesta.result.objCantidadesImportadas
        );

        setObraElegida(false);
      } else {
        console.log("NO TRAE NADA EN importarTareas()");
        // setState([]);
      }

      setMostrarProgress(false);
    } else {
      setMensaje({
        text: "Seleccione una obra",
        tipo: "error",
      });

      setMostrarMensaje(true);
    }
  };

  return (
    <div>
      <Encabezado />
      <br />
      <br />
      <Grid container columns={{ xs: 12 }} border={0}>
        <Grid item xs={12} border={0}>
          <CheckObrasConCantidadDeTareas
            setObraElegida={setObraElegida}
            obraElegida={obraElegida}
          />
        </Grid>

        <Grid item xs={12} border={0}></Grid>

        {obraElegida ? (
          <>
            <Grid
              item
              xs={10}
              border={0}
              sx={{
                marginTop: "30px",
                marginLeft: "15px",
                marginBottom: "30px",
              }}
            >
              Se importarán las <strong>{obraElegida.CantidadDeTareas}</strong>{" "}
              tareas de la obra{" "}
              <strong>{obraElegida.Obra.toUpperCase()}</strong> a la obra
              actual: <strong>{obra.Obra.toUpperCase()}</strong>
            </Grid>
          </>
        ) : (
          ""
        )}

        <Grid item xs={10} border={0}>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            sx={{ marginLeft: "15px", marginBottom: "20px" }}
          >
            <Grid item xs={12}>
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => importarTareas()}
              >
                Importar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageImportarTareasDeOtraObra;
