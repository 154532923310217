import { Button, ButtonGroup } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useNavigate } from "react-router-dom";
import ModalEliminar from "../pages/general/ModalEliminar";
import { chequearPermisos, desencriptar } from "../funciones/Funciones";
import PrivilegioContext from "../contextos/PrivilegiosContext";
import AlertSinPermisos from "./AlertSinPermisos";
import { traer_color } from "../colores/Colores";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const BotoneraABM = (props) => {
  const [openModalEliminar, setOpenModalEliminar] = useState(false);
  const [seccion, setSeccion] = useState("");

  const [estado, setEstado] = useState("");

  const { mostrarAlert, setMostrarAlert, privilegios, setPrivilegios } =
    useContext(PrivilegioContext);

  // const [privilegios, setPrivilegios] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    // console.log(privilegios);

    if (privilegios != "") {
      let objobra = desencriptar(localStorage.getItem("objobra"));
      setPrivilegios(objobra.Privilegios);
    }
  }, []);

  // console.log(props);

  const eliminar = () => {
    setSeccion(props.botones.seccion);

    switch (props.botones.seccion) {
      case "SETTINGS-SOCIEDAD":
        // alert(props.botones.seccion);

        if (
          chequearPermisos(privilegios, "DOCUMENTACION_BAJA_SOCIEDAD") === true
        ) {
          setOpenModalEliminar(true);
        } else {
          setMostrarAlert(true);
        }

        break;

      case "SETTINGS-DOCUMENTO":
        // alert(props.botones.seccion);

        if (
          chequearPermisos(privilegios, "DOCUMENTACION_BAJA_DOCUMENTO") === true
        ) {
          setOpenModalEliminar(true);
        } else {
          setMostrarAlert(true);
        }

        break;

      default:
        break;
    }
  };

  const modificar = () => {
    switch (props.botones.seccion) {
      case "SETTINGS-SOCIEDAD":
        // alert(props.botones.seccion);
        // console.log(privilegios);
        if (
          chequearPermisos(privilegios, "DOCUMENTACION_MODIF_SOCIEDAD") === true
        ) {
          navigate("/modificarSociedad");
        } else {
          setMostrarAlert(true);
        }

        break;

      case "SETTINGS-DOCUMENTO":
        // alert(props.botones.seccion);

        if (
          chequearPermisos(privilegios, "DOCUMENTACION_MODIF_DOCUMENTO") ===
          true
        ) {
          navigate("/modificarDocumento");
        } else {
          setMostrarAlert(true);
        }

        break;

      default:
        break;
    }
  };

  const agregar = () => {
    switch (props.botones.seccion) {
      case "SETTINGS-LOCALES":
        // alert(props.botones.seccion);
        // console.log(privilegios);
        if (
          chequearPermisos(privilegios, "CONTROL_DE_AVANCE_ALTA_LOCAL") === true
        ) {
          navigate("/altaLocal");
        } else {
          setMostrarAlert(true);
        }

        break;

      // case "SETTINGS-DOCUMENTO":
      //   // alert(props.botones.seccion);

      //   if (
      //     chequearPermisos(privilegios, "DOCUMENTACION_MODIF_DOCUMENTO") ===
      //     true
      //   ) {
      //     navigate("/modificarDocumento");
      //   } else {
      //     setMostrarAlert(true);
      //   }

      //   break;

      default:
        break;
    }
  };

  const importar = () => {
    switch (props.botones.seccion) {
      case "SETTINGS-LOCALES":
        // alert(props.botones.seccion);
        // console.log(privilegios);
        if (
          chequearPermisos(privilegios, "CONTROL_DE_AVANCE_IMPORTAR_LOCAL") ===
          true
        ) {
          navigate("/importarLocales");
        } else {
          setMostrarAlert(true);
        }

        break;

      default:
        break;
    }
  };

  const colorImportar = traer_color("blueGrey", 800);

  const theme = createTheme({
    palette: {
      // primary: {
      //   light: "#757ce8",
      //   main: "#3f50b5",
      //   dark: "#002884",
      //   contrastText: "#fff",
      // },
      // secondary: {
      //   light: "#ff7961",
      //   main: "#f44336",
      //   dark: "#ba000d",
      //   contrastText: "#000",
      // },
      importar: {
        main: colorImportar,
      },
    },
  });

  // console.log("color: ", colorImportar);
  return (
    <>
      <AlertSinPermisos mostrarAlert={mostrarAlert} />
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        {props.botones.alta ? (
          <Button
            color="primary"
            startIcon={<AddOutlinedIcon fontSize="inherit" />}
            onClick={() => agregar()}
          >
            Agregar
          </Button>
        ) : (
          ""
        )}
        {props.botones.modif ? (
          <Button
            color="primary"
            startIcon={<EditOutlinedIcon fontSize="inherit" />}
            onClick={() => modificar()}
          >
            Modificar
          </Button>
        ) : (
          ""
        )}
        {props.botones.baja ? (
          <Button
            color="error"
            startIcon={<DeleteOutlinedIcon fontSize="inherit" />}
            onClick={() => eliminar()}
          >
            Eliminar
          </Button>
        ) : (
          ""
        )}

        {props.botones.importar ? (
          <ThemeProvider theme={theme}>
            <Button
              color="importar"
              startIcon={<ImportExportIcon fontSize="inherit" />}
              onClick={() => importar()}
            >
              Importar
            </Button>
          </ThemeProvider>
        ) : (
          ""
        )}
      </ButtonGroup>

      {openModalEliminar ? (
        <ModalEliminar
          openModalEliminar={openModalEliminar}
          setOpenModalEliminar={setOpenModalEliminar}
          estado={estado}
          setEstado={setEstado}
          seccion={seccion}
          setSeccion={setSeccion}
        />
      ) : (
        ""
      )}

      {estado === "BAJA_OK" ? navigate("/documentacion") : ""}
    </>
  );
};

export default BotoneraABM;
