import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

// import CuadroImportarTareas from "./CuadroImportarTareas";
import { fontSize } from "@mui/system";
// import { chequearPermisos, numberWithCommas } from "../../funciones/Funciones";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  InputAdornment,
  TextField,
} from "@mui/material";

import ServidorContext from "../../../contextos/ServidorContext";
import ObraContext from "../../../contextos/ObraContext";
import { chequearPermisos } from "../../../funciones/Funciones";
import { traer_color } from "../../../colores/Colores";
import MensajesContext from "../../../contextos/MensajesContext";
import ModalTareas from "./ModalTareas";
import ModalConfirm from "../../presupuestosBTP/ModalConfirm";
import ModalConfirmacion from "../../general/ModalConfirmacion";
import AlertValidForms from "../../../components/AlertValidForms";
import { pink, red } from "@mui/material/colors";

// function createData(
//   codTarea,
//   tarea,
//   categoria,
//   precioM2,
//   subtotalMateriales,
//   subtotalManoDeObra,
//   arrDetalle,
//   arrDetalleMO
// ) {
//   return {
//     codTarea,
//     tarea,
//     categoria,
//     precioM2,
//     subtotalMateriales,
//     subtotalManoDeObra,
//     detalle: arrDetalle,
//     detalleMO: arrDetalleMO,
//   };
// }

function Row(props) {
  const {
    row,
    setListaLocales,
    listaLocales,
    computoDeLocales,
    setComputoDeLocales,
  } = props;

  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const color = traer_color("blue", 50);
  const [bgColor, setBgColor] = useState(color);

  const [open, setOpen] = React.useState(false);
  const [estado, setEstado] = useState("ALTA");
  const [local, setLocal] = useState({ idLocal: "", local: "" });
  const [listaDepartamentos, setListaDepartamentos] = useState([]);

  const initTareaSeleccionada = {
    idLocal: "",
    idTarea: "",
    local: "",
    tarea: "",
    unidad: "",
  };
  const [tareaSeleccionada, setTareaSeleccionada] = useState(
    initTareaSeleccionada
  );
  //   const [codMaterial, setCodMaterial] = useState("");
  //   const [codManoDeObra, setCodManoDeObra] = useState("");
  const [idTarea, setIdTarea] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openDialogComputo, setOpenDialogComputo] = useState(false);

  const [computoMetrico, setComputoMetrico] = useState([]);

  const handleOpenAsignarTarea = (row) => {
    if (
      chequearPermisos(obra.Privilegios, "CONTROL_DE_AVANCE_ASIGNAR_TAREA") ===
      true
    ) {
      setEstado("ALTA");
      setLocal({
        idLocal: row.idLocal,
        local: row.local,
      });
      //   setTipoDeRecurso("Trabajador");
      //   setConsumo("");
      setOpenModal(true);
    } else {
      setMensaje({
        text: "No tiene permisos para asignar tareas.",
        tipo: "error",
      });
      setMostrarMensaje(true);
    }
  };

  const handleDelete = (row, idTarea) => {
    if (
      chequearPermisos(
        obra.Privilegios,
        "CONTROL_DE_AVANCE_DESASIGNAR_TAREA"
      ) === true
    ) {
      setEstado("ELIMINAR");
      setLocal({
        idLocal: row.idLocal,
        local: row.local,
      });

      setIdTarea(idTarea);

      setOpenModalConfirm(true);
    } else {
      setMensaje({
        text: "No tiene permisos para desasignar tareas.",
        tipo: "error",
      });
      setMostrarMensaje(true);
    }
  };

  const handleEliminar = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log({
    //   operacion: "BAJA_RELACION_TAREA_LOCAL",
    //   idObra: obra.IDObra,
    //   idLocal: local.idLocal,
    //   idTarea: idTarea,
    // });
    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "BAJA_RELACION_TAREA_LOCAL",
        idObra: obra.IDObra,
        idLocal: local.idLocal,
        idTarea: idTarea,
      }),
    });
    const respuesta = await datos.json();

    // console.log("resultado: ", respuesta.result.resultado);

    // alert(respuesta.result.guardado);
    if (respuesta.result.resultado === "BAJA_OK") {
      // console.log(respuesta.result.eliminado);
      // setLista(respuesta.result.listaRecursos);
      //   handleClose();
      // alert("Lista de precios actualizada.");

      // Encuentra el local y actualiza su detalle
      const localEncontrado = listaLocales.find(
        (loc) => loc.idLocal === local.idLocal
      );

      //   console.log("localEncontrado: ", localEncontrado);

      if (localEncontrado) {
        localEncontrado.detalle = localEncontrado.detalle.filter(
          (tarea) => tarea.idTarea !== idTarea
        );

        // console.log("detalle: ", localEncontrado.detalle);
      } else {
        console.log("No se encontró el local con el ID especificado.");
      }

      setMensaje({
        text: "La tarea ha sido desasignada con éxito!.",
        tipo: "success",
      });
      setMostrarMensaje(true);

      setOpenModalConfirm(false);
    } else {
      console.log("ERROR AL ELIMINAR.");
      // setState([]);
    }
  };

  const handleOpenComputo = async (idLocal, idTarea) => {
    const API_ENDPOINT = servidor + `api_departamentos.php`;

    // console.log({
    //   operacion: "TRAER_DEPARTAMENTOS_DEL_LOCAL",
    //   idObra: obra.IDObra,
    //   idLocal: idLocal,
    //   idTarea: idTarea,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_DEPARTAMENTOS_DEL_LOCAL",
        idObra: obra.IDObra,
        idLocal: idLocal,
        idTarea: idTarea,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.respuesta === "OK") {
      console.log("listaDepartamentos: ", respuesta.result.listaDepartamentos);

      let loc = respuesta.result.local;
      let tar = respuesta.result.tarea;
      let uni = respuesta.result.unidad;

      setTareaSeleccionada({
        idLocal: idLocal,
        idTarea: idTarea,
        local: loc,
        tarea: tar,
        unidad: uni,
      });
      // console.log("PASA");
      setListaDepartamentos(respuesta.result.listaDepartamentos);

      // if (
      //   respuesta.result.listaDepartamentos.length > 0 &&
      //   respuesta.result.listaDepartamentos[0].IDDepartamento != null
      // ) {
      //   console.log("PASA");
      setOpenDialogComputo(true);
      // }

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: handleOpenComputo()");
      // setState([]);
    }
  };

  const handleCloseDialogComputo = () => {
    setOpenDialogComputo(false);
  };

  //   useEffect(() => {
  //     console.log("computo: ", computoMetrico);
  //   }, [computoMetrico]);

  //   useEffect(() => {
  //     setComputoMetrico(
  //       listaDepartamentos.map((departamento) => ({
  //         idDepartamento: departamento.IDDepartamento,
  //         computo: departamento.Computo || 0, // Default value
  //       }))
  //     );
  //   }, [listaDepartamentos]);

  //   const actualizarComputo = (obj) => {
  //     setComputoMetrico((prev) =>
  //       prev.map((item) =>
  //         item.idDepartamento === obj.idDepartamento
  //           ? { ...item, computo: obj.computo }
  //           : item
  //       )
  //     );
  //   };

  useEffect(() => {
    // console.log("Datos de listaDepartamentos:", listaDepartamentos);

    if (Array.isArray(listaDepartamentos)) {
      const inicialComputo = listaDepartamentos.map((departamento) => ({
        idDepartamento: departamento.IDDepartamento,
        idLocal: departamento.IDLocal,
        idTarea: departamento.IDTarea,
        computo: departamento.Computo || "", // Toma el valor inicial de la base de datos
      }));

      //   console.log("Inicializando computoMetrico:", inicialComputo);
      setComputoMetrico(inicialComputo);
    }
  }, [listaDepartamentos]);

  //   const actualizarComputo = ({ idDepartamento, idLocal, idTarea, computo }) => {
  //     setComputoMetrico((prev) => {
  //       const actualizado = prev.map((item) =>
  //         item.idDepartamento === idDepartamento &&
  //         item.idLocal === idLocal &&
  //         item.idTarea === idTarea
  //           ? { ...item, computo } // Actualiza el valor del computo
  //           : item
  //       );

  //       //   console.log("Actualizando computoMetrico:", actualizado);
  //       return actualizado;
  //     });
  //   };

  const chequearSiHayComputo = (idLocal, idTarea) => {
    // console.log("idLocal: ", idLocal);
    // console.log("idTarea: ", idTarea);

    // let hay =
    //   computoDeLocales.find(
    //     (item) =>
    //       String(item.IDLocal) === String(idLocal) &&
    //       String(item.IDTarea) === String(idTarea)
    //   )?.Computo || "";

    let filtro = computoDeLocales.filter(
      (item) =>
        String(item.IDLocal) === String(idLocal) &&
        String(item.IDTarea) === String(idTarea)
    );

    let edicion = determinarEstado(filtro);

    // let edicion = filtro.length === 0 ? "SIN_EDICION" : filtro.find((item)=>item.Computo === 0) ? "EDICION_"

    // if (hay !== "") {
    //   console.log("idLocal: " + idLocal + " | idTarea: " + idTarea);
    // }
    return edicion;
    // (
    //   computoDeLocales.find(
    //     (item) =>
    //       String(item.IDLocal) === String(idLocal) &&
    //       String(item.IDTarea) === String(idTarea)
    //   )?.Computo || ""
    // );
  };

  function determinarEstado(filtro) {
    let todosCero = true;
    let todosMayorCero = true;

    filtro.forEach((item) => {
      const computo = parseFloat(item.Computo); // Convertimos Computo a número
      if (computo > 0) {
        todosCero = false;
      } else {
        todosMayorCero = false;
      }
    });

    if (todosCero) {
      return <AddCircleOutlineOutlinedIcon color="primary" />; //"EDICION_CERO";
    } else if (todosMayorCero) {
      return <EditOutlinedIcon color="primary" />; //"EDICION_TOTAL";
    } else {
      return <EditOutlinedIcon sx={{ color: red[300] }} />; //"EDICION_PARCIAL";
    }
  }

  const handleConfirmarComputo = async (idLocal, idTarea) => {
    const API_ENDPOINT = servidor + `api_planificaciones.php`;

    console.log({
      operacion: "GUARDAR_COMPUTO_DE_LOCAL_TAREA",
      idObra: obra.IDObra,
      idLocal: idLocal,
      idTarea: idTarea,
      computo: computoMetrico.filter(
        (computo) => computo.idLocal === idLocal && computo.idTarea === idTarea
      ),
    });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GUARDAR_COMPUTO_DE_LOCAL_TAREA",
        idObra: obra.IDObra,
        idLocal: idLocal,
        idTarea: idTarea,
        computo: computoMetrico.filter(
          (computo) =>
            computo.idLocal === idLocal && computo.idTarea === idTarea
        ),
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.respuesta === "OK") {
      setMensaje({
        text: "Cómputo métrico guardado con éxto!",
        tipo: "success",
      });

      setMostrarMensaje(true);

      setTimeout(() => {
        setComputoMetrico([]);
        setTareaSeleccionada(initTareaSeleccionada);
        setOpenDialogComputo(false);
      }, 2000);

      //   console.log("listaDepartamentos: ", respuesta.result.listaDepartamentos);

      //   let loc = respuesta.result.local;
      //   let tar = respuesta.result.tarea;
      //   let uni = respuesta.result.unidad;

      //   setTareaSeleccionada({ local: loc, tarea: tar, unidad: uni });
      //   // console.log("PASA");
      //   setListaDepartamentos(respuesta.result.listaDepartamentos);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: handleConfirmarComputo()");
      // setState([]);
    }
  };

  return (
    <React.Fragment>
      <AlertValidForms
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell component="th" scope="row">
          {row.name}
        </TableCell> */}
        <TableCell align="left" border="1px">
          <div
            style={{
              padding: "10px",
              fontSize: "20px",
              backgroundColor: bgColor,
            }}
          >
            <strong>{row.local.toUpperCase()}</strong>
          </div>
        </TableCell>
      </TableRow>
      {openModal ? (
        <ModalTareas
          setOpenModal={setOpenModal}
          openModal={openModal}
          idLocal={local.idLocal}
          local={local.local}
          setEstado={setEstado}
          setListaLocales={setListaLocales}
          listaLocales={listaLocales}
        />
      ) : (
        ""
      )}

      {openModalConfirm ? (
        <ModalConfirmacion
          openModalConfirm={openModalConfirm}
          setOpenModalConfirm={setOpenModalConfirm}
          titulo="Desasignar tarea"
          pregunta="Desea desasignar la tarea?"
          handleEliminar={handleEliminar}
        />
      ) : (
        ""
      )}

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Tareas del local
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Cód. Tarea</TableCell>
                    <TableCell align="left">Tarea</TableCell>
                    <TableCell align="left">Cómputo</TableCell>
                    <TableCell align="left">Unidad</TableCell>
                    <TableCell align="left">Gremio</TableCell>
                    <TableCell align="left">Categoría</TableCell>
                    {/* <TableCell align="left">Modificar</TableCell> */}
                    <TableCell align="left">Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {console.log("row: ", row)} */}
                  {row.detalle.map((detalleRow, index) => (
                    <TableRow key={detalleRow.codTarea}>
                      <TableCell component="th" scope="row" align="left">
                        {detalleRow.codTarea}
                      </TableCell>
                      <TableCell align="left">{detalleRow.tarea}</TableCell>
                      <TableCell align="left">
                        <Button
                          variant="text"
                          onClick={() =>
                            handleOpenComputo(row.idLocal, detalleRow.idTarea)
                          }
                        >
                          {chequearSiHayComputo(
                            row.idLocal,
                            detalleRow.idTarea
                          )}
                          {/* {detalleRow.computoMetrico} */}
                        </Button>
                      </TableCell>
                      <TableCell align="left">{detalleRow.unidad}</TableCell>
                      <TableCell align="left">{detalleRow.gremio}</TableCell>
                      <TableCell align="left">{detalleRow.categoria}</TableCell>
                      {/* <TableCell align="left">
                        <IconButton aria-label="delete" size="medium">
                          <EditOutlinedIcon
                            fontSize="inherit"
                            onClick={() =>
                              handleOpenModifTarea(
                                row.codLocal,
                                detalleRow.codTarea,
                                detalleRow.tarea,
                                detalleRow.unidad,
                                detalleRow.gremio,
                                detalleRow.categoria
                              )
                            }
                          />
                        </IconButton>
                      </TableCell> */}
                      <TableCell align="left">
                        <IconButton aria-label="delete" size="medium">
                          <HighlightOffOutlinedIcon
                            fontSize="inherit"
                            onClick={() =>
                              handleDelete(row, detalleRow.idTarea)
                            }
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow key={"LOC-" + row.codLocal} style={{ height: 50 }}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      style={{ fontSize: "18px" }}
                    >
                      <IconButton aria-label="delete" size="large">
                        <AddCircleOutlineOutlinedIcon
                          fontSize="inherit"
                          onClick={() => handleOpenAsignarTarea(row)}
                        />
                      </IconButton>
                      Tarea
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {listaDepartamentos.length > 0 ? (
        listaDepartamentos[0].IDDepartamento != null ? (
          <Dialog open={openDialogComputo} onClose={handleCloseDialogComputo}>
            <DialogTitle>Cómputo métrico</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Por favor complete el cómputo métrico de la tarea{" "}
                <strong>{tareaSeleccionada.tarea}</strong> del local{" "}
                <strong>{tareaSeleccionada.local}</strong>
                <br />
              </DialogContentText>

              {(listaDepartamentos || [])
                // .filter(
                //   (item) =>
                //     item.IDTarea === tareaSeleccionada.idTarea ||
                //     item.IDTarea === null
                // )
                .map((departamento) => (
                  <>
                    <br />
                    <strong>
                      {departamento.Departamento.toUpperCase()}:
                    </strong>{" "}
                    <br />
                    <Input
                      id="standard-adornment-weight"
                      endAdornment={
                        <InputAdornment position="end">
                          {tareaSeleccionada.unidad}
                        </InputAdornment>
                      }
                      aria-describedby="standard-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      sx={{ width: "100px" }}
                      value={
                        // Encuentra el valor actual en computoMetrico o usa una cadena vacía
                        computoMetrico.find(
                          (item) =>
                            String(item.idDepartamento) ===
                              String(departamento.IDDepartamento) &&
                            String(item.idLocal) ===
                              String(tareaSeleccionada.idLocal) &&
                            String(item.idTarea) ===
                              String(tareaSeleccionada.idTarea)
                        )?.computo || ""
                      }
                      onChange={(e) => {
                        // Actualiza el estado de computoMetrico
                        const nuevoComputo = e.target.value;

                        setComputoMetrico((prev) => {
                          // Si computoMetrico es un array, actualiza el valor correspondiente
                          if (Array.isArray(prev)) {
                            const existe = prev.some(
                              (item) =>
                                String(item.idDepartamento) ===
                                  String(departamento.IDDepartamento) &&
                                String(item.idLocal) ===
                                  String(tareaSeleccionada.idLocal) &&
                                String(item.idTarea) ===
                                  String(tareaSeleccionada.idTarea)
                            );

                            if (existe) {
                              // Actualiza el elemento existente
                              return prev.map((item) =>
                                String(item.idDepartamento) ===
                                  String(departamento.IDDepartamento) &&
                                String(item.idLocal) ===
                                  String(tareaSeleccionada.idLocal) &&
                                String(item.idTarea) ===
                                  String(tareaSeleccionada.idTarea)
                                  ? { ...item, computo: nuevoComputo }
                                  : item
                              );
                            } else {
                              // Agrega un nuevo elemento si no existe
                              return [
                                ...prev,
                                {
                                  idDepartamento: departamento.IDDepartamento,
                                  idLocal: tareaSeleccionada.idLocal,
                                  idTarea: tareaSeleccionada.idTarea,
                                  computo: nuevoComputo,
                                },
                              ];
                            }
                          }

                          // Si prev no es un array, inicializa como un array con el nuevo elemento
                          return [
                            {
                              idDepartamento: departamento.IDDepartamento,
                              idLocal: tareaSeleccionada.idLocal,
                              idTarea: tareaSeleccionada.idTarea,
                              computo: nuevoComputo,
                            },
                          ];
                        });
                      }}
                    />
                    {/* <Input
                id="standard-adornment-weight"
                endAdornment={
                  <InputAdornment position="end">
                    {tareaSeleccionada.unidad}
                  </InputAdornment>
                }
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                sx={{ width: "100px" }}
                value={
                  computoMetrico.find(
                    (item) =>
                      item.idDepartamento === departamento.IDDepartamento &&
                      item.idLocal === tareaSeleccionada.IDLocal &&
                      item.idTarea === tareaSeleccionada.IDTarea
                  )?.computo || "" // Si no encuentra el valor, muestra una cadena vacía
                }
                onChange={(e) => {
                  const nuevoComputo = e.target.value
                    ? parseFloat(e.target.value)
                    : "";
                  actualizarComputo({
                    idDepartamento: departamento.IDDepartamento,
                    idLocal: tareaSeleccionada.IDLocal,
                    idTarea: tareaSeleccionada.IDTarea,
                    computo: nuevoComputo,
                  });
                }}
              /> */}
                    <br />
                    {/* <TextField
                autoFocus
                variant="standard"
                margin="dense"
                id="name"
                label="Piso"
                type="text"
                fullWidth
                // value={plano}
                // onChange={(e) => setPlano(e.target.value)}
              /> */}
                  </>
                ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogComputo}>Cancelar</Button>
              <Button
                onClick={() =>
                  handleConfirmarComputo(
                    tareaSeleccionada.idLocal,
                    tareaSeleccionada.idTarea
                  )
                }
                color="primary"
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog open={openDialogComputo} onClose={handleCloseDialogComputo}>
            <DialogTitle>El local no está asignado a ningún plano.</DialogTitle>
            <DialogActions>
              <Button onClick={handleCloseDialogComputo}>Cancelar</Button>
            </DialogActions>
          </Dialog>
        )
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    codLocal: PropTypes.string.isRequired,
    local: PropTypes.string,
    detalle: PropTypes.arrayOf(
      PropTypes.shape({
        codTarea: PropTypes.string.isRequired,
        tarea: PropTypes.string.isRequired,
        unidad: PropTypes.string.isRequired,
        gremio: PropTypes.string.isRequired,
        categoria: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

const BoxABMLocales = ({
  setListaLocales,
  listaLocales,
  computoDeLocales,
  setComputoDeLocales,
}) => {
  const [lista, setLista] = useState([]);
  const [actualizaLocal, setActualizaLocal] = useState(false);
  const { servidor } = useContext(ServidorContext);
  const { obra, setObra } = useContext(ObraContext);

  const [mostrarProgress, setMostrarProgress] = useState(false);

  //   useEffect(() => {
  //     listar_tareas_con_recursos();
  //   }, [actualizaLocal]);

  //   const listar_tareas_con_recursos = async () => {
  //     // console.log(lista);

  //     setMostrarProgress(true);

  //     const API_ENDPOINT = servidor + `api_presupuestos.php`;

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   "Content-Type": "application/json",
  //       // },
  //       body: JSON.stringify({
  //         operacion: "LISTAR_TAREAS_CON_RECURSOS",
  //         idObra: obra.IDObra,
  //         //   lista: texto,
  //         // lista:
  //         //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log(respuesta);

  //     if (respuesta.result.listaTareas) {
  //       // console.log(respuesta.result.listaTareas);
  //       // console.log("PASA");
  //       setLista(respuesta.result.listaTareas);

  //       // alert("Lista de precios actualizada.");
  //     } else {
  //       console.log("NO TRAE NADA EN: listar_tareas_con_recursos()");
  //       // setState([]);
  //     }

  //     setMostrarProgress(false);
  //   };

  return listaLocales.length > 0 ? (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "20px" }} />
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listaLocales.map((row, index) => (
            <>
              {/* {console.log("row: ", row)} */}
              <Row
                key={index}
                row={row}
                setListaLocales={setListaLocales}
                listaLocales={listaLocales}
                computoDeLocales={computoDeLocales}
                setComputoDeLocales={setComputoDeLocales}
              />
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : mostrarProgress ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  ) : (
    ""
    // <CuadroImportarTareas />
  );
};

export default BoxABMLocales;
