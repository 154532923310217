import React, { useContext, useEffect, useState } from "react";
import Titulo from "../../../components/Titulo";
import { Box, Grid, Typography } from "@mui/material";
import EstilosContext from "../../../contextos/EstilosContext";
import { chequearPermisos } from "../../../funciones/Funciones";
import PrivilegioContext from "../../../contextos/PrivilegiosContext";
import { traer_color } from "../../../colores/Colores";
import BoxABMLocales from "./BoxABMLocales";
import ServidorContext from "../../../contextos/ServidorContext";
import ObraContext from "../../../contextos/ObraContext";

const PageABMLocales = () => {
  const { estilos, screenWidth } = useContext(EstilosContext);
  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);

  const { privilegios, setPrivilegios, mostrarAlert, setMostrarAlert } =
    useContext(PrivilegioContext);

  const colorTitulo = traer_color("blue", 600);
  const [bgColorTitulo, setBgColorTitulo] = useState(colorTitulo);

  const color = traer_color("blue", 50);
  const [bgColor, setBgColor] = useState(color);

  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [listaLocales, setListaLocales] = useState([]);

  const [computoDeLocales, setComputoDeLocales] = useState([]);
  useEffect(() => {
    const getComputos = async () => {
      await traer_computos();
    };

    getComputos();
  }, []);

  const traer_computos = async () => {
    const API_ENDPOINT = servidor + `api_departamentos.php`;

    console.log({
      operacion: "TRAER_COMPUTOS_DE_LOCALES",
      idObra: obra.IDObra,
    });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_COMPUTOS_DE_LOCALES",
        idObra: obra.IDObra,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.respuesta === "OK") {
      console.log("listaComputos: ", respuesta.result.listaComputos);

      setComputoDeLocales(respuesta.result.listaComputos);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: traer_computos()");
      // setState([]);
    }
  };

  useEffect(() => {
    traer_locales_y_tareas();
  }, []);

  const traer_locales_y_tareas = async () => {
    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_LOCALES_Y_TAREAS",
        idObra: obra.IDObra,
        //   listaTareas: texto,
        // listaTareas:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.respuesta === "OK") {
      // console.log("listaLocales: ", respuesta.result.listaLocales);

      // console.log("PASA");
      setListaLocales(respuesta.result.listaLocales);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: traer_locales_y_tareas()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  return (
    <>
      <div>
        {/* {console.log("privilegios", privilegios)} */}
        {chequearPermisos(privilegios, "CONTROL_DE_AVANCE_ALTA_TAREAS") ===
        true ? (
          // <BoxContad
          //   imagen="planificacion.png"
          //   cantidad={0}
          //   titulo="Planificacion"
          //   link="planificacion"
          // />
          <>
            <Titulo
              texto="LOCALES"
              botones={{
                seccion: "SETTINGS-LOCALES",
                alta: true,
                baja: false,
                modif: false,
                importar: true,
              }}
              estilos={estilos}
            />
            <Typography variant="h6" style={{ fontSize: "16px" }}>
              Agregue, edite o elimine locales y sus tareas!
            </Typography>
            <br /> <br />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 1,
                m: 1,
                mt: 2,
                // border: 1,
                height: "auto",
              }}
            >
              <Grid
                container
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Ajusta la dirección a columna
                      justifyContent: "center",
                      width: "100%",
                      backgroundColor: bgColorTitulo,
                      fontSize:
                        screenWidth === "xs" || screenWidth === "sm"
                          ? "26px"
                          : "60px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "25px",
                        color: "#FFFFFF",
                        paddingLeft: "10px",
                        /*padding: "15px",*/
                        // border: "1px solid #FF0000",
                      }}
                    >
                      {/* ESPACIOS: */}
                      Lista de locales
                    </div>
                    <div></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Ajusta la dirección a columna
                      justifyContent: "center",
                      width: "100%",
                      // backgroundColor: bgColor,
                      fontSize:
                        screenWidth === "xs" || screenWidth === "sm"
                          ? "26px"
                          : "60px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        width: "100%",
                        paddingLeft: "5px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <BoxABMLocales
                        setListaLocales={setListaLocales}
                        listaLocales={listaLocales}
                        computoDeLocales={computoDeLocales}
                        setComputoDeLocales={setComputoDeLocales}
                      />
                      {/* <BoxUnidadesFuncionales
                        idPlano={item.IDPlano}
                        setUfSelected={setUfSelected}
                      /> */}
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  item
                  style={{
                    width:
                      screenWidth === "xs" || screenWidth === "sm"
                        ? "100%"
                        : "40%",
                  }}
                >
                  &nbsp;
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default PageABMLocales;
