import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import Select from "react-select";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Input, InputAdornment, Slider, useMediaQuery } from "@mui/material";
import ServidorContext from "../../contextos/ServidorContext";
import { useContext } from "react";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "auto",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

const ModalModifAsign = ({
  openModal,
  setOpenModal,
  editarTarea,
  setEditarTarea,
}) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  const { servidor } = useContext(ServidorContext);

  const [oldCuantificacion, setOldCuantificacion] = useState(
    editarTarea.cuantificacion
  );
  const [newCuantificacion, setNewCuantificacion] = useState(
    editarTarea.cuantificacion
  );
  const [newAvance, setNewAvance] = useState("");

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  const handleChange = (event) => {
    console.log(event);
    // setEditarTarea(event.)
    // props.setUnidadDelRecurso(event.unidadDelRecurso);
    // setCodRecurso(event.value);
  };

  //   useEffect(() => {
  //     listar_recursos();
  //   }, []);

  //   const listar_recursos = async () => {
  //     // console.log(lista);

  //     const API_ENDPOINT = servidor + `api_presupuestos.php`;

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   "Content-Type": "application/json",
  //       // },
  //       body: JSON.stringify({
  //         operacion: "LISTAR_RECURSOS",
  //         tipoDeRecurso: props.tipoDeRecurso,

  //         //   lista: texto,
  //         // lista:
  //         //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log(respuesta);

  //     if (respuesta.result.listaRecursos) {
  //       // console.log(respuesta.result.listaRecursos);
  //       setLista(respuesta.result.listaRecursos);

  //       // alert("Lista de precios actualizada.");
  //     } else {
  //       console.log("NO TRAE NADA.");
  //       // setState([]);
  //     }
  //   };

  //   const validConsumo = (value) => {
  //     setConsumo(value.target.value);
  //     setConsumoModif(value.target.value);
  //   };

  //   const handleGuardar = async () => {
  //     const API_ENDPOINT = servidor + `api_presupuestos.php`;

  //     let formAlta = {
  //       operacion: "ALTA_RELACION_TAREA_RECURSO",
  //       codigoTarea: props.codTarea,
  //       codigoRecurso: codRecurso,
  //       consumo: consumo,
  //     };

  //     let formModi = {
  //       operacion: "MODIFICACION_RELACION_TAREA_RECURSO",
  //       codigoTarea: props.codTarea,
  //       codigoRecurso: props.codRecurso,
  //       consumo: consumo,
  //     };

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   "Content-Type": "application/json",
  //       // },
  //       body:
  //         props.estado === "ALTA"
  //           ? JSON.stringify(formAlta)
  //           : JSON.stringify(formModi),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log(respuesta);
  //     // alert(respuesta.result.guardado);
  //     if (respuesta.result.guardado === "ALTA_OK") {
  //       // console.log(respuesta.result.guardado);
  //       // setLista(respuesta.result.listaRecursos);

  //       props.setEstado("ALTA_OK");
  //       setCodRecurso("");
  //       setConsumo("");
  //       // setCosto("");
  //       handleClose();

  //       // alert("Lista de precios actualizada.");
  //     } else if (respuesta.result.guardado === "MODIFICACION_OK") {
  //       // console.log(respuesta.result.guardado);
  //       // setLista(respuesta.result.listaRecursos);

  //       props.setEstado("MODIFICACION_OK");
  //       setCodRecurso("");
  //       setConsumo("");
  //       // setCosto("");
  //       handleClose();
  //     } else {
  //       console.log("ERROR AL GUARDAR.");
  //       // setState([]);
  //     }
  //   };

  function valuetext(value) {
    // setEditarTarea({ ...editarTarea, avance: value });

    setNewAvance(value);
    // console.log("value: ", value);

    return `${value} ${editarTarea.unidad}`;
  }

  const validCuantificacion = (event) => {
    // console.log(event.target.value);

    // setEditarTarea((prev) => ({ ...prev, cuantificacion: event.target.value }));

    setOldCuantificacion(event.target.value);

    setNewCuantificacion(event.target.value);

    // setEditarTarea({ ...editarTarea, cuantificacion: event.target.value });
  };

  const handleGuardar = () => {
    // setEditarTarea({
    //   ...editarTarea,
    //   cuantificacion: newCuantificacion,
    //   avance: newAvance,
    // });

    const updatedTarea = {
      ...editarTarea,
      cuantificacion: newCuantificacion,
      cuantificacionPendiente: newCuantificacion,
      avance: newAvance,
      actualizar: true,
    };

    setEditarTarea(updatedTarea);
    // updateItem(updatedTarea); // Actualizas el item aquí

    // console.log({
    //   ...editarTarea,
    //   cuantificacion: newCuantificacion,
    //   avance: newAvance,
    // });

    handleClose();
  };

  const isMobile = useMediaQuery("(max-width:600px)"); // Detecta dispositivos móviles

  // Estilo dinámico para el modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "98%" : "22%", // 99% en móviles, 50% en escritorio
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    boxShadow: 24,
    // borderRadius: "8px", // Bordes redondeados
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}

      <Box component="form" sx={style} noValidate autoComplete="off">
        {/* <TextField
            id="standard-basic"
            label="Standard"
            helperText="hola"
            variant="standard"
          /> */}

        {/* <FormControl sx={{ m: 1, minWidth: 80 }}> */}
        <FormControl variant="standard" sx={{ m: 1, width: "auto" }}>
          <Typography variant="h5" component="h5">
            MODIFICAR ASIGNACIÓN
          </Typography>

          {/* <InputLabel id="demo-simple-select-autowidth-label">
            Recurso
          </InputLabel> */}
          <div>&nbsp;</div>

          {/* <TextField
            id="consumo"
            label="Consumo"
            variant="standard"
            onChange={validConsumo}
            value={consumoModif}

            // onChange={validConsumo}
          /> */}

          <Input
            id="cuantificacion"
            placeholder="Cuantificación"
            value={oldCuantificacion}
            onChange={validCuantificacion}
            endAdornment={
              <InputAdornment position="end">
                {editarTarea.unidad}
              </InputAdornment>
            }
            aria-describedby="standard-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />

          <div>&nbsp;</div>

          <Box sx={{ width: "99%", marginTop: "50px" }}>
            <Slider
              aria-label="Always visible"
              defaultValue={editarTarea.avance}
              getAriaValueText={valuetext}
              step={5}
              marks={marks}
              valueLabelDisplay="on"
            />
          </Box>

          {/* <TextField
            id="costo"
            label="Costo"
            variant="standard"
            onChange={validCosto}
          />

          <div>&nbsp;</div> */}

          <Button
            variant="contained"
            endIcon={<CheckOutlinedIcon />}
            onClick={handleGuardar}
          >
            Guardar
          </Button>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default ModalModifAsign;
