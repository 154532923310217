import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";

import {
  chequearPermisos,
  desencriptar,
  encriptar,
} from "../../../funciones/Funciones";
import ServidorContext from "../../../contextos/ServidorContext";
import EstilosContext from "../../../contextos/EstilosContext";
import ObraContext from "../../../contextos/ObraContext";
import MensajesContext from "../../../contextos/MensajesContext";
import AlertValidConfig from "../../../components/AlertValidConfig";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ControlDeAvanceContext from "../../../contextos/ControlDeAvanceContext";

const filter = createFilterOptions();

const BoxGremios = ({
  setGremioSelected,
  gremioSelected,
  ufSelected,
  localSelected,
  // idPlano,
}) => {
  const { servidor } = useContext(ServidorContext);
  const { isMobile, screenWidth } = useContext(EstilosContext);
  const { obra, setObra } = useContext(ObraContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  //   const [gremioSelected, setGremioSelected] = useState("");
  const [comboGremios, setComboGremios] = useState([]);

  const [gremios, setGremios] = useState({
    IDGremio: "",
    Gremio: "",
  });

  const [chipData, setChipData] = useState([]);
  const [chipSelected, setChipSelected] = useState([]);
  const [mostrarModalGremios, setMostrarModalGremios] = useState(false);

  const [value, setValue] = useState(null);
  const [open, toggleOpen] = useState(false);

  const [dialogValue, setDialogValue] = useState({
    IDGremio: "",
    Gremio: "",
  });

  const initValid = { gremioSelectedValid: false };
  const [validForm, setValidForm] = useState(initValid);

  //   useEffect(() => {
  // setPaso1(desencriptar(localStorage.getItem("objordserv")));
  //   }, []);

  // console.log("SECTOR: ", sector);

  useEffect(() => {
    // console.log("ufSelected.IDDepartamento: ", ufSelected.IDDepartamento);
    // console.log("localSelected.IDLocal: ", localSelected);

    traer_gremios();
  }, [ufSelected, localSelected]);

  //   useEffect(() => {
  //     // console.log("PASA POR sector.IDSector: ", sector.IDSector);
  //     traer_gremios();
  //   }, [sector.IDSector]);

  //   useEffect(() => {
  //     traer_gremios();

  //     // setChipSelected(gremioSelected);
  //   }, []);

  useEffect(() => {
    // console.log("chipData: ", chipData);
  }, [chipData]);

  //   useEffect(() => {
  //     // console.log("idGrupo: ", dialogValue.IDGrupo);

  //     if (asignar) {
  //       asignar_grupo();

  //       setValue({
  //         IDGrupo: parseInt(dialogValue.IDGrupo, 10),
  //         Grupo: dialogValue.Grupo,
  //       });

  //       setAsignar(false);
  //     }
  //   }, [asignar]);

  useEffect(() => {
    // return () => {
    // let paso1 = desencriptar(localStorage.getItem("objordserv"));
    // let paso2 = { ...paso1, grupoSelected: chipSelected };

    // localStorage.setItem("objordserv", encriptar(paso2));

    setGremioSelected(chipSelected);

    // console.log("chipSelected: ", chipSelected);

    // console.log("paso2: ", paso2);
    // };
  }, [chipSelected]);

  // const guardar_gremio = async () => {
  //   const API_ENDPOINT = servidor + `api_gremios.php`;

  //   // console.log("idPlano: ", plano.IDPlano);
  //   // console.log("dialogValue.Grupo: ", dialogValue.Grupo);

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "GUARDAR_GREMIO",
  //       idObra: obra.IDObra,
  //       gremio: dialogValue.Gremio,
  //       capataz: "",

  //       // productos: "VOLQUETES",
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log("respuesta 1: ", respuesta);

  //   if (respuesta.result.resultado === "OK") {
  //     // console.log("result: ", respuesta.result.palabrasClaves[0]);
  //     // setChipData([...chipData, respuesta.result.grupos[0]]);

  //     // setGroupSelected("");
  //     // setGrupoSelected("");

  //     setDialogValue({
  //       IDGremio: respuesta.result.gremios[0]["IDGremio"],
  //       Gremio: respuesta.result.gremios[0]["Gremio"],
  //     });

  //     // setGrupoSelected({
  //     //   ...grupoSelected,
  //     //   IDGrupo: respuesta.result.grupos[0]["IDGrupo"],
  //     //   Grupo: respuesta.result.grupos[0]["Grupo"],
  //     // });

  //     setAsignar(true);
  //     // setMensaje({ text: "Grupo agregado con éxito!", tipo: "success" });

  //     // setMostrarMensaje(true);
  //   } else {
  //     console.log("NO TRAE NADA EN guardar_grupo()");
  //     // setState([]);
  //   }
  // };

  const traer_gremios = async () => {
    const API_ENDPOINT = servidor + `api_gremios.php`;

    // console.log("IDObra: ", obra.IDObra);
    // console.log("idDepartamento: ", idDepartamento);
    // console.log("sector.IDSector: ", sector.IDSector);

    // console.log({
    //   operacion: "TRAER_GRUPOS",
    //   idObra: obra.IDObra,
    //   idDepartamento: idDepartamento,
    //   idSector: sector.IDSector,
    //   idPlano: plano.IDPlano,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_GREMIOS_DE_LOCAL",
        idObra: obra.IDObra,
        // idPlano: plano.IDPlano,
        idDepartamento: ufSelected.IDDepartamento,
        idLocal: localSelected.IDLocal,
      }),
    });
    const respuesta = await datos.json();

    // console.log("listaGruposEnCombo 2: ", respuesta.result.listaGruposEnCombo);

    if (respuesta.result.resultado === "OK") {
      // console.log("listaGremios: ", respuesta.result.listaGremios);
      setChipData(respuesta.result.listaGremios);
    } else {
      setChipData([]);

      console.log("NO TRAE NADA EN traer_gremios()");
      // setState([]);
    }
  };

  const handleClick = (chip) => {
    // let found = chipSelected.filter(
    //   (chip) => chip.IDPalabraClave === idPalabraClave
    // );
    // console.log("found: ", found);

    // let newChip = "";
    // if (found.length > 0) {
    //   newChip = chipSelected.filter(
    //     (chip) => chip.IDPalabraClave !== idPalabraClave
    //   );
    //   setChipSelected(newChip);
    // } else {
    //   newChip = { IDPalabraClave: idPalabraClave };
    //   setChipSelected([...chipSelected, newChip]);
    // }

    // console.log("chip: ", chip);
    // console.log("chipSelected: ", chipSelected);
    // console.log(chip.IDGrupo + " === " + chipSelected.IDGrupo);

    if (chip.IDGremio === chipSelected.IDGremio) {
      setGremioSelected("");
      setChipSelected("");
    } else {
      setGremioSelected(chip);
      setChipSelected(chip);
    }
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   if (dialogValue.Gremio !== "") {
  //     if (
  //       chipData.filter((chip) => chip.Gremio === dialogValue.Gremio).length > 0
  //     ) {
  //       setMensaje({ text: "El gremio ya existe.", tipo: "error" });

  //       setMostrarMensaje(true);
  //     } else {
  //       if (
  //         comboGremios.filter((item) => item.IDGremio === dialogValue.IDGremio)
  //           .length === 0
  //       ) {
  //         guardar_gremio();
  //       } else {
  //         asignar_gremio();
  //       }

  //       // asignar_grupo();

  //       // setValue({
  //       //   IDGrupo: parseInt(dialogValue.IDGrupo, 10),
  //       //   Grupo: dialogValue.Grupo,
  //       // });
  //       handleClose();
  //     }
  //   } else {
  //     setMensaje({ text: "El gremio no puede ser vacío.", tipo: "error" });

  //     setMostrarMensaje(true);
  //   }
  // };

  // const handleClose = () => {
  //   setDialogValue({
  //     IDGremio: "",
  //     Gremio: "",
  //   });
  //   toggleOpen(false);

  //   setMostrarModalGremios(false);
  // };

  // const handleAddGremio = () => {
  //   console.log("ufSelected.IDDepartamento: ", ufSelected.IDDepartamento);
  //   console.log("localSelected.IDLocal: ", localSelected.IDLocal);

  //   if (
  //     chequearPermisos(
  //       obra.Privilegios,
  //       "CONTROL_DE_AVANCE_ROL_ADMINISTRADOR"
  //     ) === true
  //   ) {
  //     if (
  //       ufSelected.IDDepartamento !== "" &&
  //       ufSelected.IDDepartamento !== 0 &&
  //       ufSelected.IDDepartamento !== undefined
  //     ) {
  //       if (
  //         localSelected.IDLocal !== "" &&
  //         localSelected.IDLocal !== 0 &&
  //         localSelected.IDLocal !== undefined
  //       ) {
  //         setMostrarModalGremios(true);
  //         toggleOpen(true);
  //       } else {
  //         setMensaje({
  //           text: "Seleccione el local!",
  //           tipo: "error",
  //         });

  //         setMostrarMensaje(true);
  //       }
  //     } else {
  //       setMensaje({
  //         text: "Seleccione el espacio!",
  //         tipo: "error",
  //       });

  //       setMostrarMensaje(true);
  //     }
  //   } else {
  //     setMensaje({ text: "No tiene permisos!", tipo: "error" });
  //     setMostrarMensaje(true);
  //   }
  // };

  //   const asignar_grupo = async () => {
  //     const API_ENDPOINT = servidor + `api_grupos.php`;

  //     // console.log("idPlano: ", plano.IDPlano);
  //     // console.log("dialogValue.Grupo: ", dialogValue.Grupo);
  //     // console.log("dialogValue.IDGrupo: ", dialogValue.IDGrupo);

  //     // console.log(API_ENDPOINT);
  //     // console.log({
  //     //   operacion: "ASIGNAR_GRUPO",
  //     //   idObra: obra.IDObra,
  //     //   idDepartamento: idDepartamento,
  //     //   idSector: sector.IDSector,
  //     //   idGrupo: dialogValue.IDGrupo,
  //     //   grupo: dialogValue.Grupo,
  //     //   asignacionMultiple: asignacionMultiple,
  //     //   idPlano: plano.IDPlano,
  //     // });

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   "Content-Type": "application/json",
  //       // },
  //       body: JSON.stringify({
  //         operacion: "ASIGNAR_GRUPO",
  //         idObra: obra.IDObra,
  //         idDepartamento: idDepartamento,
  //         idSector: sector.IDSector,
  //         idGrupo: dialogValue.IDGrupo,
  //         grupo: dialogValue.Grupo,
  //         asignacionMultiple: asignacionMultiple,
  //         idPlano: plano.IDPlano,
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log("respuesta: ", respuesta);

  //     if (respuesta.result.resultado === "OK") {
  //       // console.log("result: ", respuesta.result.palabrasClaves[0]);
  //       setChipData([...chipData, respuesta.result.grupos[0]]);

  //       setGrupoSelected({
  //         ...grupoSelected,
  //         IDGrupo: respuesta.result.grupos[0]["IDGrupo"],
  //         Grupo: respuesta.result.grupos[0]["Grupo"],
  //       });

  //       // setGroupSelected("");
  //       // setGrupoSelected("");
  //       setMensaje({ text: "Grupo asignado con éxito!", tipo: "success" });

  //       setMostrarMensaje(true);
  //       // setAsignar(false);
  //     } else {
  //       console.log("NO TRAE NADA EN asignar_grupo()");
  //       // setState([]);
  //     }
  //   };

  //   const handleCheck = (event) => {
  //     // console.log("Check: ", event.target.checked);

  //     if (event.target.checked) {
  //       setAsignacionMultiple(true);
  //     } else {
  //       setAsignacionMultiple(false);
  //     }
  //   };

  return (
    <>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      {/* <br /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {chipData.map((data, index) => (
          // <div
          //   style={
          //     {
          //       /*padding: "20px"*/
          //     }
          //   }
          // >
          <Chip
            key={data.IDGremio}
            label={data.Gremio.toUpperCase()}
            color="primary"
            // variant=""
            variant={
              // chipSelected.length > 0 &&
              // chipSelected.filter((chip) => chip.IDGrupo === data.IDGrupo)
              //   .length > 0
              chipSelected !== "" && chipSelected.IDGremio === data.IDGremio
                ? ""
                : "outlined"
            }
            onClick={() => handleClick(data)}
            style={{ fontSize: "25px", padding: "0px" }} // Ajusta estos valores a tu gusto
          />
          // </div>
        ))}

        {/* <Chip
          key="agregar"
          label="+"
          color="primary"
          // variant={
          //   chipSelected.length > 0 &&
          //   chipSelected.filter(
          //     (chip) => chip.IDGrupo === data.IDGrupo
          //   ).length > 0
          //     ? ""
          //     : "outlined"
          // }
          variant="outlined"
          onClick={() => handleAddGremio()}
          style={{ fontSize: "25px", padding: "10px" }} // Ajusta estos valores a tu gusto
        /> */}
      </div>
      {/* <Dialog open={mostrarModalGremios} onClose={handleClose}>
        <form>
          <DialogTitle>Agregar Gremio</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ejemplos: "Albañiles", "Pintores", "Yeseros", etc.
            </DialogContentText>

            <br />

            <Autocomplete
              id="free-solo-dialog-demo"
              options={comboGremios}
              value={value}
              // inputValue={value === null ? "" : value.Tarea}
              clearOnBlur={false}
              selectOnFocus
              handleHomeEndKeys
              freeSolo
              // disabled={!camposDisponibles.tarea}
              onChange={(event, newValue) => {
                // console.log("newValue: ", newValue);

                if (typeof newValue === "string") {
                  // console.log("PASA POR 1");

                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    // toggleOpen(true);

                    setDialogValue({
                      IDGremio: "",
                      Gremio: newValue,
                    });

                    setGremios({
                      ...gremios,
                      IDGremio: "",
                      Gremio: newValue,
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  // console.log("PASA POR 2: Cuando agrega un grupo.");

                  toggleOpen(true);
                  setDialogValue({
                    IDGremio: "",
                    Gremio: newValue.inputValue,
                  });

                  setGremios({
                    ...gremios,
                    IDGremio: "",
                    Gremio: newValue.inputValue,
                  });
                } else {
                  // console.log(
                  //   "PASA POR 3: Cuando selecciona un grupo existente."
                  // );
                  setValue(newValue);

                  if (newValue !== null) {
                    setDialogValue({
                      IDGremio: newValue.IDGremio,
                      Gremio: newValue.Gremio,
                    });

                    setGremios({
                      ...gremios,
                      IDGremio: newValue.IDGremio,
                      Gremio: newValue.Gremio,
                    });
                  } else {
                    setDialogValue({
                      IDGremio: "",
                      Gremio: "",
                    });

                    setGremios({
                      ...gremios,
                      IDGremio: "",
                      Gremio: "",
                    });
                  }
                }

                // setLista([...lista, newValue]);

                if (newValue === null) {
                  setGremioSelected({ IDGremio: "", Gremio: "" });
                } else {
                  setGremioSelected(newValue);
                }
                // alert(JSON.stringify(newValue));

                setValidForm({ ...validForm, gremioValid: true });
                // setCamposDisponibles({ ...camposDisponibles, unidad: true });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // console.log("options: ", options);
                // console.log("params: ", params);

                if (
                  params.inputValue !== "" &&
                  !options.some(
                    (item) =>
                      item.Gremio.toLowerCase() ===
                      params.inputValue.toLowerCase()
                  )
                ) {
                  filtered.push({
                    inputValue: params.inputValue,
                    Gremio: `Agregar "${params.inputValue}"`,
                  });
                }
                // console.log("filtered: ", filtered);
                return filtered;
              }}
              getOptionLabel={(option) => {
                // console.log("option: ", option);
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  // console.log("PASA POR A");
                  return option;
                }
                if (option && option.inputValue) {
                  // console.log("PASA POR B");
                  return option.inputValue;
                }
                if (option && option.Gremio) {
                  // console.log("PASA POR C");

                  return option.Gremio;
                }
                return "";
                // if (typeof option === "string") {
                //   return option;
                // }
                // if (option.inputValue) {
                //   return option.inputValue;
                // }
                // console.log("option.inputValue: ", option.inputValue);
                // return option.Tarea;
              }}
              // clearOnBlur

              renderOption={(props, option) => (
                <li {...props} key={option.IDGremio}>
                  {option.Gremio}
                </li>
              )}
              // sx={{ width: 300 }}

              renderInput={(params) => (
                <TextField {...params} label="Gremio" variant="standard" />
              )}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleSubmit}>Agregar</Button>
          </DialogActions>
        </form>
      </Dialog> */}
    </>
  );
};

export default BoxGremios;
