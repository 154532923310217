import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import Select from "react-select";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Input, InputAdornment } from "@mui/material";
import ServidorContext from "../../contextos/ServidorContext";
import { useContext } from "react";
import ObraContext from "../../contextos/ObraContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalRecursos = (props) => {
  const handleClose = () => {
    props.setUnidadDelRecurso("");
    props.setOpenModal(false);
  };

  const defaultValueEnSelect = {
    label: "",
    value: "0",
    unidadDelRecurso: "",
  };

  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);

  const [codRecurso, setCodRecurso] = useState(defaultValueEnSelect);
  const [lista, setLista] = useState([]);
  const [consumo, setConsumo] = useState("");
  // const [costo, setCosto] = useState("");
  const [consumoModif, setConsumoModif] = useState(props.consumo);

  const handleChange = (event) => {
    // console.log(event);
    props.setUnidadDelRecurso(event.unidadDelRecurso);
    setCodRecurso(event.value);
  };

  useEffect(() => {
    listar_recursos();
  }, []);

  const listar_recursos = async () => {
    // console.log(lista);

    const API_ENDPOINT = servidor + `api_presupuestos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_RECURSOS",
        idObra: obra.IDObra,
        tipoDeRecurso: props.tipoDeRecurso,

        //   lista: texto,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.listaRecursos) {
      // console.log(respuesta.result.listaRecursos);
      setLista(respuesta.result.listaRecursos);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA.");
      // setState([]);
    }
  };

  const validConsumo = (value) => {
    setConsumo(value.target.value);
    setConsumoModif(value.target.value);
  };

  // const validCosto = (value) => {
  //   setCosto(value.target.value);
  // };

  const handleGuardar = async () => {
    const API_ENDPOINT = servidor + `api_presupuestos.php`;

    let formAlta = {
      operacion: "ALTA_RELACION_TAREA_RECURSO",
      idObra: obra.IDObra,
      codigoTarea: props.codTarea,
      codigoRecurso: codRecurso,
      consumo: consumo,
    };

    let formModi = {
      operacion: "MODIFICACION_RELACION_TAREA_RECURSO",
      idObra: obra.IDObra,
      codigoTarea: props.codTarea,
      codigoRecurso: props.codRecurso,
      consumo: consumo,
    };

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body:
        props.estado === "ALTA"
          ? JSON.stringify(formAlta)
          : JSON.stringify(formModi),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);
    // alert(respuesta.result.guardado);
    if (respuesta.result.guardado === "ALTA_OK") {
      // console.log(respuesta.result.guardado);
      // setLista(respuesta.result.listaRecursos);

      props.setEstado("ALTA_OK");
      setCodRecurso("");
      setConsumo("");
      // setCosto("");
      handleClose();

      // alert("Lista de precios actualizada.");
    } else if (respuesta.result.guardado === "MODIFICACION_OK") {
      // console.log(respuesta.result.guardado);
      // setLista(respuesta.result.listaRecursos);

      props.setEstado("MODIFICACION_OK");
      setCodRecurso("");
      setConsumo("");
      // setCosto("");
      handleClose();
    } else {
      console.log("ERROR AL GUARDAR.");
      // setState([]);
    }
  };

  return (
    <Modal
      open={props.openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}

      <Box
        component="form"
        sx={style}
        noValidate
        autoComplete="off"
        // style={{ border: "1px solid #FF0000", width: "50%" }}
      >
        {/* <TextField
            id="standard-basic"
            label="Standard"
            helperText="hola"
            variant="standard"
          /> */}

        {/* <FormControl sx={{ m: 1, minWidth: 80 }}> */}
        <FormControl variant="standard" sx={{ m: 1, width: "auto" }}>
          <Typography variant="h5" component="h5">
            {props.estado === "ALTA" ? "AGREGAR RECURSO" : "MODIFICAR RECURSO"}
          </Typography>

          {/* <InputLabel id="demo-simple-select-autowidth-label">
            Recurso
          </InputLabel> */}
          <div>&nbsp;</div>
          {props.estado === "ALTA" ? (
            // <Select
            //   labelId="demo-simple-select-label"
            //   id="demo-simple-select"
            //   // labelId="demo-simple-select-standard-label"
            //   // id="demo-simple-select-standard"
            //   value={codRecurso}
            //   label="Recurso"
            //   defaultValue="Recurso"
            //   onChange={handleChange}
            // >
            //   {lista.map((rowRecurso) => (
            //     <MenuItem
            //       value={rowRecurso.CodRecurso}
            //       key={rowRecurso.IDRecurso}
            //     >
            //       {"(" + rowRecurso.CodRecurso + ") " + rowRecurso.Recurso}
            //     </MenuItem>
            //   ))}
            // </Select>

            <Select
              // defaultValue={codTarea}
              onChange={handleChange}
              options={lista}
              // value={defaultValueEnSelect}
              // value={lista.filter((option) => option.label === "00-DEM-020")}
              value={lista.filter(function (option) {
                return option.value === codRecurso;
              })}
              // style={{ width: "60%" }}
            />
          ) : (
            <Typography variant="h6" component="h6">
              {props.recurso}
            </Typography>
          )}
          <div>&nbsp;</div>

          {/* <TextField
            id="consumo"
            label="Consumo"
            variant="standard"
            onChange={validConsumo}
            value={consumoModif}

            // onChange={validConsumo}
          /> */}

          <Input
            id="consumo"
            placeholder="Consumo"
            value={consumoModif}
            onChange={validConsumo}
            endAdornment={
              <InputAdornment position="end">
                {props.unidadDelRecurso}
              </InputAdornment>
            }
            aria-describedby="standard-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />

          <div>&nbsp;</div>

          {/* <TextField
            id="costo"
            label="Costo"
            variant="standard"
            onChange={validCosto}
          />

          <div>&nbsp;</div> */}

          <Button
            variant="contained"
            endIcon={<CheckOutlinedIcon />}
            onClick={handleGuardar}
          >
            Guardar
          </Button>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default ModalRecursos;
