import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Box,
  ButtonGroup,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Titulo from "../../components/Titulo";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import Select from "react-select";
import ObraContext from "../../contextos/ObraContext";
import {
  desencriptar,
  enviarPosicionHaciaReactNative,
  formatearFecha,
  formatearHora,
} from "../../funciones/Funciones";
import AlertValidForms from "../../components/AlertValidForms";
import MensajesContext from "../../contextos/MensajesContext";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import DatePicker from "react-datepicker";
import WhatsappEnviarMensaje from "../../components/WhatsappEnviarMensaje";
import EstilosContext from "../../contextos/EstilosContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import { useNavigate } from "react-router-dom";
import MaterialItem from "./MaterialItem";
import PedidoContext from "../../contextos/PedidoContext";
import DashboardContext from "../../contextos/DashboardContext";
import ModalInfo from "../general/ModalInfo";
import AgregarProveedor from "../proveedores/AgregarProveedor";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ServidorContext from "../../contextos/ServidorContext";
import AsignarProveedor from "../proveedores/AsignarProveedor";

const steps = ["PROVEEDOR", "MATERIALES", "DETALLE", "ENVÍO"];

const PageAltaPedido = () => {
  let navigate = useNavigate();

  const { servidor } = useContext(ServidorContext);
  const { dashboard, setDashboard } = useContext(DashboardContext);

  const { usuario, setUsuario } = useContext(UsuarioContext);
  const {
    pedido,
    setPedido,
    codigoPedido,
    setCodigoPedido,
    limpiarPedido,
    setLimpiarPedido,
  } = useContext(PedidoContext);

  const { obra, setObra } = useContext(ObraContext);
  const {
    mostrarMensaje,
    setMostrarMensaje,
    mensaje,
    setMensaje,
    mostrarModal,
    setMostrarModal,
  } = useContext(MensajesContext);

  // const { pedido, setPedido } = useContext(PedidoContext);

  const { screenWidth, isMobile } = useContext(EstilosContext);

  // const [interferencia, setInterferencia] = useState({});
  const [mensajeModal, setMensajeModal] = useState("");

  // const [pedido, setPedido] = useState(initPedido);
  const [materiales, setMateriales] = useState([]);

  const [proveedores, setProveedores] = useState([]);
  const [proveedorElegido, setProveedorElegido] = useState([]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [count, setCount] = React.useState(1);

  const [fechas, setFechas] = useState(new Date());
  const [horas, setHoras] = useState(new Date());

  const [actualizarMateriales, setActualizarMateriales] = useState(false);

  const [fechaElegida, setFechaElegida] = useState(
    formatearFecha("HOY", "dd/MM/yyyy")
    // formatearFecha(new Date(), "dd/MM/yyyy")
  );
  const [horaElegida, setHoraElegida] = useState(
    formatearHora(new Date(), "hh:mm aa")
  );

  const [mensajeWP, setMensajeWP] = useState("");

  const [mostrarFormAsignarProveedor, setMostrarFormAsignarProveedor] =
    useState(false);

  const isStepOptional = (step) => {
    return step === -1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const generarCodigoDePedido = async () => {
    const API_ENDPOINT = servidor + `api_pedidos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GENERAR_CODIGO_DE_PEDIDO",
        // idObra: obra.IDObra,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.codigoPedido) {
      setPedido({
        ...pedido,
        codigoPedido: respuesta.result.codigoPedido,
      });
    } else {
      console.log("NO TRAE NADA EN generarCodigoDePedido()");
      // setState([]);
    }
  };

  const cargarMateriales = async () => {
    const API_ENDPOINT = servidor + `api_pedidos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_MATERIALES_PARA_PEDIDOS",
        idObra: obra.IDObra,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.materiales) {
      // console.log(respuesta.result.materiales);
      setMateriales(respuesta.result.materiales);
    } else {
      console.log("NO TRAE NADA EN cargarMateriales()");
      // setState([]);
    }
  };

  const cargarProveedores = async () => {
    const API_ENDPOINT = servidor + `api_proveedores.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_PROVEEDORES_ASIGNADOS",
        idObra: obra.IDObra,
        rubros: "",
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.proveedores) {
      // console.log(respuesta.result.proveedores);
      setProveedores(respuesta.result.proveedores);
      // setProveedorElegido(respuesta.result.proveedores[0]);
      // console.log(respuesta.result.proveedores);
      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN cargarProveedores()");
      // setState([]);
    }
  };

  useEffect(() => {
    let objobra = localStorage.getItem("objobra");

    setObra(desencriptar(objobra));
    // console.log(desencriptar(objobra));

    if (usuario.IDUsuario === undefined) {
      // console.log("PASA POR IDObra");
      let objusu = desencriptar(localStorage.getItem("objusu"));
      setUsuario(objusu);
      // console.log(objusu);
    } else {
      // console.log(usuario);
    }

    if (dashboard.listaPedidos === undefined) {
      let objDash = localStorage.getItem("objdashboard");

      setDashboard(desencriptar(objDash));
    } else {
      // console.log(usuario);
    }

    generarCodigoDePedido();
    cargarProveedores();
    cargarMateriales();

    // setFechaElegida(formatearFecha(new Date(), "dd/MM/yyyy"));
  }, []);

  useEffect(() => {
    cargarMateriales();
    setActualizarMateriales(false);
  }, [actualizarMateriales]);

  // useEffect(() => {
  //   if (isMobile && window.ReactNativeWebView) {
  //     window.ReactNativeWebView.postMessage(
  //       JSON.stringify({
  //         accion: "altaPedido",
  //         contenido: {
  //           usuario: usuario,
  //           obra: obra,
  //         },
  //       })
  //     );
  //   }
  // }, []);

  useEffect(() => {
    // console.log("usu: ", usuario);
    // enviarPosicionHaciaReactNative(isMobile, "banner", {
    //   usuario: usuario,
    //   obra: obra,
    //   tipoDeBanner: "INTERSTICIAL",
    //   idPublicidad: "ca-app-pub-9747826938175612/5432206994",
    //   idBanner: "ca-app-pub-9747826938175612/8150779805",
    //   sacarFoto: false,
    // });
  }, []);

  useEffect(() => {
    let msg =
      // "Qué tal " +
      // proveedorElegido.NombreContacto +
      // "?"

      "Hola!, \nTe paso un pedido para *" + obra.Obra + "*: \n\n";
    // msg += "Cant. Volquetes: " + count + "\n\n";
    msg += "Razón Social: " + obra.RazonSocial + "\n";
    msg += "CUIT: " + obra.Cuit + "\n\n";
    msg += "Fecha: " + fechaElegida + "\n";
    msg += "Hora: " + horaElegida + "\n\n";
    // msg += "Para ver el pedido ingresá al siguiente link: \n";
    // msg +=
    //   "https://plus.bimtracker.com/confirmarPedido/" +
    //   pedido.codigoPedido +
    //   "\n\n";

    msg += "*Pedido*: \n";

    pedido.detalle.map(
      (item, index) =>
        (msg +=
          item.cantidad + " " + item.unidad + " de " + item.material + "\n")
    );

    msg += "\n";
    msg += "\n";
    // msg += "codigoPedido: " + codigoPedido + "\n";
    // msg += "\n";
    // msg += "\n";

    msg += "Pedido enviado desde https://plus.bimtracker.com";

    let fechaProgramada = fechas;
    let horaProgramada = formatearHora(horas);

    // console.log(fechaProgramada);
    // console.log(horaProgramada);

    // console.log(pedido.detalle);

    // console.log("codigoPedido: " + pedido.codigoPedido);
    // console.log(pedido);

    setPedido({
      // codigoPedido: codigoPedido,
      ...pedido,
      idUsuario: usuario.IDUsuario,
      idObra: obra.IDObra,
      idProveedor: proveedorElegido.IDProveedor,
      proveedor: proveedorElegido.Proveedor,
      fechaProgramada: fechaProgramada,
      horaProgramada: horaProgramada,
      detalle: [...pedido.detalle],
    });

    // setPedido([
    //   {
    //     idUsuario: usuario.IDUsuario,
    //     idObra: obra.IDObra,
    //     idProveedor: proveedorElegido.IDProveedor,
    // //     detalle: [...pedido.detalle],
    //     // cantidad: count,
    //     fechaProgramada: fechaProgramada,
    //     horaProgramada: horaProgramada,
    //   },
    // ]);

    setMensajeWP(msg);

    // console.log(pedido);
  }, [activeStep, codigoPedido]);

  useEffect(() => {
    if (limpiarPedido) {
      // console.log("LIMPIAR PEDIDO");

      // Limpio el pedido
      setPedido("INIT");

      navigate("/dashboardPedidos");
    }
  }, [limpiarPedido]);

  useEffect(() => {
    // buscarInterferenciaDePedidos();
    // console.log("dashboard: ", dashboard);
    // console.log("fechaElegida: ", fechaElegida);

    if (dashboard && dashboard.listaPedidos) {
      let pedidosEnFechaElegida = dashboard.listaPedidos.filter(
        (ped) =>
          obra.IDObra === ped.IDObra &&
          ped.FechaProgramada === formatearFecha(fechaElegida)
      );

      // console.log("pedidosEnFechaElegida: ", pedidosEnFechaElegida);

      let volquetesEnFechaElegida = (dashboard.listaVolquetes || []).filter(
        (vol) =>
          obra.IDObra === vol.IDObra &&
          vol.FechaProgramada === formatearFecha(fechaElegida)
      );

      if (
        pedidosEnFechaElegida.length > 0 ||
        volquetesEnFechaElegida.length > 0
      ) {
        let msg =
          "Para la fecha " +
          fechaElegida +
          " hay programados " +
          pedidosEnFechaElegida.length +
          " pedidos y " +
          volquetesEnFechaElegida.length +
          " volquetes.<br><br>";

        if (pedidosEnFechaElegida.length > 0) {
          msg += `<b style="color: #000000;">Pedidos programados para esta fecha:</b><br><br>`;
        }
        for (var i = 0; i < pedidosEnFechaElegida.length; i++) {
          // msg += `<b style="color: #000000;">${pedidosEnFechaElegida[i].IDPedido}</b>`;
          msg += `<b style="color: #000000; background-color: ${pedidosEnFechaElegida[i].ProveedorColor}; padding: 2px 5px; border-radius: 5px;">${pedidosEnFechaElegida[i].Proveedor}</b> <b style="color: #000000; background-color: #CCCCCC; padding: 2px 5px; border-radius: 5px;">#${pedidosEnFechaElegida[i].IDPedido}</b> <br>`;
          msg += "<ul>";

          pedidosEnFechaElegida[i].arrDetalle.forEach((detalle) => {
            msg += `<li style="color: #000000;">${detalle.Cantidad} ${detalle.Unidad} de ${detalle.Material}</li>`;
          });

          msg += "</ul>---------------------";
        }

        setMensajeModal(msg);

        // setMostrarModal({
        //   mostrar: activeStep === 2 ? true : false,
        //   titulo: "INTERFERENCIAS",
        //   mensaje: msg,
        // });
      }

      // console.log("pedidosEnFechaElegida: ", pedidosEnFechaElegida);
    }
  }, [fechaElegida, dashboard]);

  useEffect(() => {
    setMostrarModal({
      mostrar: mensajeModal !== "" && activeStep === 2 ? true : false,
      titulo: "INTERFERENCIAS",
      mensaje: <span dangerouslySetInnerHTML={{ __html: mensajeModal }} />,
    });
  }, [mensajeModal, activeStep]);

  // useEffect(() => {
  //   // let msg = "El día " + fechaElegida + " se"

  //   console.log("dashboard: ", dashboard);

  //   // setMensaje({tipo: "info", mensaje: msg})
  //   // setMostrarMensaje(true)
  // }, [interferencia]);

  useEffect(() => {
    // console.log(screenWidth);
  });

  // useEffect(() => {
  //   // console.log(proveedorElegido);
  // }, [proveedorElegido]);

  // useEffect(() => {
  //   // console.log("Mostrar: " + mostrarMensaje);
  //   // if (mostrarMensaje) {
  //   //   navigate("/dashboard");
  //   // }
  // }, [mostrarMensaje]);

  const addFecha = (fecha) => {
    let date = formatearFecha(fecha, "yyyy-MM-dd");
    let dateElegida = formatearFecha(fecha, "dd/MM/yyyy");
    // setFechas([...fechas, date]);
    setFechas(date);
    setFechaElegida(dateElegida);

    // console.log("fecha: " + fecha);
    // console.log("date: " + date);
    // console.log("fechas: " + fechas);
  };

  const addHora = (hora) => {
    let hour = formatearHora(hora);
    // setHoras([...horas, hora]);
    setHoras(hora);
    setHoraElegida(hour);

    // console.log("hora: " + hour);
    // console.log("horas: " + horas);
  };

  const seleccionarProveedor = (prov) => {
    setProveedorElegido(prov);

    // console.log("proveedorElegido: ", prov);
    // console.log("proveedor: ");
    // console.log(prov);
    setPedido({
      ...pedido,
      idProveedor: prov.IDProveedor,
      proveedor: prov.Proveedor,
      // detalle: [],
    });
    // console.log(pedido);
  };

  const handleAsignarProveedor = () => {
    setMostrarFormAsignarProveedor(true);
  };

  const styles = {
    container: {
      // width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    contenedor: {
      // position: "absolute",
      // top: "50%",
      // left: "50%",
      // transform: "translate(-50%, -50%)",
      display: "flex",
      justifyContent: "center",
      // width: "100vh",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      // p: 2,
    },
    exito: {
      color: "#13b90b",
      marginBottom: 10,
      alignItems: "flex-start",
      width: "70%",
      // borderWidth: 1,
      // borderColor: '#FFFFFF',
    },
    error: {
      color: "#FF0000",
      marginBottom: 10,
      alignItems: "flex-start",
      width: "70%",
      // borderWidth: 1,
      // borderColor: '#FFFFFF',
    },
  };

  return (
    <>
      <AlertValidForms
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />

      <ModalInfo
        mostrarModal={mostrarModal}
        setMostrarModal={setMostrarModal}
        // info={info}
        // setInfo={setInfo}
      />

      <Grid
        container
        style={{
          // border: "1px solid #FF0000",
          display: "flex",
          justifyContent: "center",
          // width: "98%",
        }}
        sx={{ width: "100%" }}
      >
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Titulo
            texto="Hacer un pedido"
            botones={{
              seccion: "",
              alta: false,
              baja: false,
              modif: false,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={8}
          xl={6}
          style={{ paddingTop: "35px" }}
        >
          <Box
            component="form"
            sx={styles.contenedor}
            noValidate
            autoComplete="off"

            // width="100%"
          >
            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
              <div>&nbsp;</div>

              <Box sx={{ width: "100%" }}>
                {!isMobile ? (
                  <>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        // if (isStepOptional(index)) {
                        //   labelProps.optional = (
                        //     <Typography variant="caption">Optional</Typography>
                        //   );
                        // }
                        // if (isStepSkipped(index)) {
                        //   stepProps.completed = false;
                        // }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    <br />
                  </>
                ) : (
                  ""
                )}

                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* <Typography sx={{ mt: 2, mb: 1 }}>
                      Step {activeStep + 1}
                    </Typography> */}

                    {activeStep === 0 ? (
                      <>
                        {mostrarFormAsignarProveedor ? (
                          <AsignarProveedor
                            setProveedores={setProveedores}
                            proveedores={proveedores}
                            setMostrarFormAsignarProveedor={
                              setMostrarFormAsignarProveedor
                            }
                          />
                        ) : (
                          // <AgregarProveedor
                          //   setProveedores={setProveedores}
                          //   proveedores={proveedores}
                          //   setMostrarFormAgregarProveedor={
                          //     setMostrarFormAgregarProveedor
                          //   }
                          // />
                          <>
                            <div
                              style={{ marginBottom: "30px", fontSize: "16px" }}
                            >
                              Seleccione el proveedor:
                            </div>
                            <IconButton
                              color="primary"
                              aria-label="agregar proveedor"
                              onClick={() => handleAsignarProveedor()}
                            >
                              <AddCircleIcon sx={{ fontSize: 40 }} />
                            </IconButton>
                            ASIGNAR PROVEEDOR
                            <Grid item>&nbsp;</Grid>
                            <FormControl sx={{ pl: 1 }}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                // defaultValue="female"
                                name="radio-buttons-group"
                              >
                                {proveedores.map((item, index) => (
                                  // <div key={item.IDProveedor}>
                                  <FormControlLabel
                                    value={item.IDProveedor}
                                    key={item.IDProveedor}
                                    control={
                                      <Radio
                                        // checked={index === 0}
                                        icon={
                                          <CheckCircleOutlinedIcon
                                            sx={{ fontSize: 40 }}
                                          />
                                        }
                                        checkedIcon={
                                          <CheckCircleIcon
                                            sx={{ fontSize: 40 }}
                                          />
                                        }
                                        onChange={() =>
                                          seleccionarProveedor(item)
                                        }
                                      />
                                    }
                                    label={item.Proveedor.toUpperCase()}
                                  />
                                  // </div>
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </>
                        )}
                      </>
                    ) : activeStep === 1 ? (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <div
                              style={{ marginBottom: "30px", fontSize: "16px" }}
                            >
                              Seleccione los materiales:
                            </div>
                          </Grid>

                          <MaterialItem
                            materiales={materiales}
                            proveedores={proveedores}
                            proveedorElegido={proveedorElegido}
                            setActualizarMateriales={setActualizarMateriales}
                          />

                          <Grid item xs={12}>
                            &nbsp;
                          </Grid>
                        </Grid>
                      </>
                    ) : activeStep === 2 ? (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <div
                              style={{ marginBottom: "30px", fontSize: "16px" }}
                            >
                              Seleccione la fecha y hora de entrega:
                            </div>
                          </Grid>
                          {/* <Grid item xs={12}>
                            <Badge color="secondary" badgeContent={count}>
                              <LocalShippingOutlinedIcon fontSize="large" />
                            </Badge>
                            &nbsp;&nbsp;
                            <ButtonGroup sx={{ pl: 2 }}>
                              <Button
                                aria-label="reduce"
                                onClick={() => {
                                  setCount(Math.max(count - 1, 0));
                                }}
                              >
                                <RemoveIcon fontSize="small" />
                              </Button>
                              <Button
                                aria-label="increase"
                                onClick={() => {
                                  setCount(count + 1);
                                }}
                              >
                                <AddIcon fontSize="small" />
                              </Button>
                            </ButtonGroup>
                          </Grid> */}
                          <Grid item xs={12}>
                            &nbsp;
                          </Grid>
                          <Grid item xs={9} sm={8} md={6} lg={6} xl={5}>
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              // selected={fechas}
                              onChange={(date) => addFecha(date)}
                              // showTimeSelect
                              inline
                            />
                          </Grid>
                          <Grid item xs={3} sm={4} md={6} lg={6} xl={7}>
                            <DatePicker
                              // dateFormat="dd/MM/yyyy"
                              selected={horas}
                              onChange={(time) => addHora(time)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="hh:mm aa"
                              inline
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : activeStep === 3 ? (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            &nbsp;
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6" component="h6">
                              Resumen del pedido:
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            &nbsp;
                          </Grid>
                          <Grid item xs={12}>
                            <strong>Obra: </strong>
                            {obra.Obra}
                          </Grid>
                          <Grid item xs={12}>
                            <strong>Proveedor: </strong>
                            {proveedorElegido.Proveedor}
                          </Grid>
                          <Grid item xs={12}>
                            <strong>Contacto: </strong>
                            {proveedorElegido.NombreContacto}
                          </Grid>
                          {/* <Grid item xs={12}>
                            <strong>Cant. Volquetes: </strong>
                            {count}
                          </Grid> */}
                          <Grid item xs={12}>
                            <strong>Fecha: </strong>
                            {fechaElegida}
                          </Grid>
                          <Grid item xs={12}>
                            <strong>Hora: </strong>
                            {horaElegida}
                          </Grid>
                          <Grid item xs={12}>
                            &nbsp;
                          </Grid>
                          <Grid item xs={12}>
                            <strong>Pedido: </strong>
                            {pedido.detalle.map((item, index) => (
                              <Grid item xs={12}>
                                {item.cantidad + " " + item.unidad} de{" "}
                                {item.material}
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          &nbsp;
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Volver
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {isStepOptional(activeStep) && (
                        <Button
                          color="inherit"
                          onClick={handleSkip}
                          sx={{ mr: 1 }}
                        >
                          Saltear
                        </Button>
                      )}

                      {activeStep === steps.length - 1 ? (
                        <>
                          <WhatsappEnviarMensaje
                            //   numeroTel="5491158482073"
                            numeroTel={
                              // usuario.EsAdmin ?
                              proveedorElegido.Whatsapp
                              // : obra.WhatsappAdminObra
                            }
                            mensaje={mensajeWP}
                            pedido={pedido}
                            setMostrarMensaje={setMostrarMensaje}
                            setMensaje={setMensaje}
                            tipo="PEDIDO_DE_MATERIALES"
                            setLimpiarPedido={setLimpiarPedido}
                            limpiarPedido={limpiarPedido}
                          />
                        </>
                      ) : (
                        <Button onClick={handleNext}>
                          {activeStep === steps.length - 1
                            ? "Enviar"
                            : "Siguiente"}
                        </Button>
                      )}
                      {/* <Button onClick={handleNext}>
                        {activeStep === steps.length - 1
                          ? "Enviar"
                          : "Siguiente"}
                      </Button> */}
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
};

export default PageAltaPedido;
