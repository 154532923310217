import { Button, Grid, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import ServidorContext from "../../../contextos/ServidorContext";
import EstilosContext from "../../../contextos/EstilosContext";
import UsuarioContext from "../../../contextos/UsuarioContext";
import ObraContext from "../../../contextos/ObraContext";
import MensajesContext from "../../../contextos/MensajesContext";

import { useNavigate } from "react-router-dom";
import CheckLocalesConTareas from "./CheckLocalesConTareas";
import CheckTareasDelLocal from "./CheckTareasDelLocal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const PageImportarLocales = ({ setTitulo, setPagina }) => {
  const { servidor } = useContext(ServidorContext);
  const { screenWidth } = useContext(EstilosContext);
  const { usuario } = useContext(UsuarioContext);
  const { obra } = useContext(ObraContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const initLocalElegido = { IDLocal: 0, Local: "", ListaTareas: [] };
  const [localElegido, setLocalElegido] = useState(initLocalElegido);
  const [localEditado, setLocalEditado] = useState(localElegido.Local);
  const [localesConTareas, setLocalesConTareas] = useState([]);
  const [tareasElegidas, setTareasElegidas] = useState([]);
  const [mostrarTareas, setMostrarTareas] = useState(false);
  const [mostrarCambioDeNombre, setMostrarCambioDeNombre] = useState(false);
  const [mostrarProgress, setMostrarProgress] = useState(false);

  //   let navigate = useNavigate();

  useEffect(() => {
    setTitulo("Importar locales");
    setPagina("");

    // console.log("A");
  }, [setTitulo]);

  useEffect(() => {
    console.log("localElegido: ", localElegido);
    console.log("tareasElegidas: ", tareasElegidas);

    // const llamar_a_importar_tareas = async () => {
    //   await importarTareas();
    // };

    // if (obraElegida) {
    //   llamar_a_importar_tareas();
    // }
  }, [localElegido, tareasElegidas]);

  useEffect(() => {
    setLocalEditado(localElegido.Local);
  }, [localElegido]);

  useEffect(() => {
    traer_locales_con_tareas();
  }, []);

  const traer_locales_con_tareas = async () => {
    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_locales.php`;

    // console.log("plano: ", plano);
    // console.log("imagen: ", imagen);
    // console.log("idUsuario: ", usuario.IDUsuario);
    // console.log("idObra: ", obra.IDObra);

    let inicio = new Date();
    console.log("INI: ", inicio);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_LOCALES_CON_TAREAS_DE_TODAS_LAS_OBRAS",
        // idObra: obra.IDObra,
        idUsuario: usuario.IDUsuario,
      }),
    });
    const respuesta = await datos.json();

    let fin = new Date();
    console.log("FIN: ", fin);

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      // setMensaje({ text: "Plano agregado con éxito!", tipo: "success" });
      // setMostrarMensaje(true);

      console.log("listaLocales: ", respuesta.result.listaLocales);

      setLocalesConTareas(respuesta.result.listaLocales);
    } else {
      console.log("NO TRAE NADA EN traer_locales_con_tareas()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const importarLocales = async () => {
    if (tareasElegidas.length > 0) {
      setMostrarProgress(true);
      const API_ENDPOINT = servidor + `api_locales.php`;
      // console.log("plano: ", plano);
      // console.log("imagen: ", imagen);
      // console.log("idUsuario: ", usuario.IDUsuario);
      // console.log("idObra: ", obra.IDObra);
      let inicio = new Date();
      console.log("INI: ", inicio);

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "IMPORTAR_LOCALES_DE_OTRA_OBRA",
          idObraDestino: obra.IDObra,
          local: localEditado,
          tareasElegidas: tareasElegidas,
        }),
      });
      const respuesta = await datos.json();

      let fin = new Date();
      console.log("FIN: ", fin);

      // console.log("respuesta: ", respuesta);
      if (respuesta.result.resultado === "OK") {
        // setMensaje({ text: "Plano agregado con éxito!", tipo: "success" });
        // setMostrarMensaje(true);
        setMensaje({
          text:
            "Se han importado " +
            respuesta.result.cantidadTareasImportadas +
            " tareas con éxito!",
          tipo: "success",
        });
        setMostrarMensaje(true);

        console.log(
          "cantidadTareasImportadas: ",
          respuesta.result.cantidadTareasImportadas
        );

        console.log("existeLocal: ", respuesta.result.existeLocal > 0);

        setLocalElegido(initLocalElegido);
        setMostrarTareas(false);
      } else {
        console.log("NO TRAE NADA EN importarLocales()");
        // setState([]);
      }
      setMostrarProgress(false);
    } else {
      setMensaje({
        text: "Seleccione una obra",
        tipo: "error",
      });
      setMostrarMensaje(true);
    }
  };

  const siguiente = (paso) => {
    if (paso === 1) {
      setMostrarCambioDeNombre(true);
    } else if (paso === 2) {
      setMostrarCambioDeNombre(false);
      setTareasElegidas([]);
      setMostrarTareas(true);

      console.log("localEditado: ", localEditado);
    }
  };

  const atras = (paso) => {
    if (paso === 0) {
      setMostrarCambioDeNombre(false);
      setLocalElegido(initLocalElegido);
    } else if (paso === 1) {
      setMostrarTareas(false);
      setMostrarCambioDeNombre(true);
    }
  };

  const todas = () => {
    setTareasElegidas(localElegido.ListaTareas);
  };

  const ninguna = () => {
    setTareasElegidas([]);
  };

  const handleLocalChange = (event) => {
    setLocalEditado(event.target.value); // Solo actualizas el valor local
  };

  return (
    <div>
      {!mostrarTareas && !mostrarCambioDeNombre ? (
        <Grid container columns={{ xs: 12 }} border={0}>
          <Grid
            item
            xs={10}
            border={0}
            sx={{
              marginTop: "30px",
              marginLeft: "15px",
              marginBottom: "30px",
              fontSize: "20px",
            }}
          >
            Seleccione el local a importar:
          </Grid>
          <Grid item xs={12} border={0}>
            <CheckLocalesConTareas
              localesConTareas={localesConTareas}
              setLocalElegido={setLocalElegido}
              localElegido={localElegido}
            />
          </Grid>
          <Grid item xs={12} border={0}></Grid>
          <Grid item xs={10} border={0}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{
                marginLeft: "15px",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={() => siguiente(1)}
                  disabled={localElegido.Local === ""}
                >
                  Siguiente
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : !mostrarTareas && mostrarCambioDeNombre ? (
        <Grid container columns={{ xs: 12 }} border={0}>
          <Grid
            item
            xs={10}
            border={0}
            sx={{
              marginTop: "30px",
              marginLeft: "15px",
              marginBottom: "30px",
              fontSize: "20px",
            }}
          >
            Importar con otro nombre:
          </Grid>
          <Grid item xs={12} border={0}>
            <TextField
              id="standard-basic"
              label="Local"
              variant="standard"
              sx={{ marginLeft: "15px" }}
              value={localEditado} // Este es el valor editado por el usuario
              onChange={handleLocalChange} // Solo actualizas el estado local
            />
          </Grid>
          <Grid item xs={12} border={0}></Grid>
          <Grid item xs={10} border={0}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{
                marginLeft: "15px",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => atras(0)}
                >
                  atras
                </Button>
                &nbsp;
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={() => siguiente(2)}
                  disabled={localEditado === ""}
                >
                  Siguiente
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : mostrarTareas && !mostrarCambioDeNombre ? (
        <Grid container columns={{ xs: 12 }} border={0}>
          <Grid
            item
            xs={10}
            border={0}
            sx={{
              marginTop: "30px",
              marginLeft: "15px",
              marginBottom: "30px",
              fontSize: "20px",
            }}
          >
            Seleccione las tareas del local{" "}
            <strong>{localElegido.Local}</strong> que desea importar
            {console.log(localEditado + "!=" + localElegido.Local)}
            {localEditado !== localElegido.Local ? (
              <>
                &nbsp;(se importarán con nombre de local:{" "}
                <strong>{localEditado}</strong>):
              </>
            ) : (
              ":"
            )}
          </Grid>
          <Grid
            item
            xs={12}
            border={0}
            sx={{ marginLeft: "15px", marginBottom: "20px" }}
          >
            <Button
              variant={
                tareasElegidas.length === localElegido.ListaTareas.length
                  ? "outlined"
                  : "text"
              }
              //   endIcon={<ArrowForwardIcon />}
              onClick={() => todas()}
            >
              Todas
            </Button>
            &nbsp;
            <Button
              variant={tareasElegidas.length === 0 ? "outlined" : "text"}
              //   endIcon={<ArrowForwardIcon />}
              onClick={() => ninguna()}
            >
              Ninguna
            </Button>
          </Grid>
          <Grid item xs={12} border={0}>
            <CheckTareasDelLocal
              tareasDelLocal={localElegido.ListaTareas}
              setTareasElegidas={setTareasElegidas}
              tareasElegidas={tareasElegidas}
            />
          </Grid>
          <Grid item xs={12} border={0}></Grid>
          <Grid item xs={10} border={0}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{
                marginLeft: "15px",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => atras(1)}
                >
                  atras
                </Button>
                &nbsp;
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => importarLocales()}
                  disabled={tareasElegidas.length === 0}
                >
                  importar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </div>
  );
};

export default PageImportarLocales;
