import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ServidorContext from "../../contextos/ServidorContext";
import EstilosContext from "../../contextos/EstilosContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import ObraContext from "../../contextos/ObraContext";
import MensajesContext from "../../contextos/MensajesContext";

const CheckObrasConCantidadDeTareas = ({ setObraElegida, obraElegida }) => {
  const { servidor } = useContext(ServidorContext);
  const { screenWidth } = useContext(EstilosContext);
  const { usuario } = useContext(UsuarioContext);
  const { obra } = useContext(ObraContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [obrasConCantTareas, setObrasConCantTareas] = useState([]);

  useEffect(() => {
    traer_obras_con_cantidad_de_tareas();
  }, []);

  const traer_obras_con_cantidad_de_tareas = async () => {
    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_tareas.php`;

    // console.log("plano: ", plano);
    // console.log("imagen: ", imagen);
    // console.log("idUsuario: ", usuario.IDUsuario);
    // console.log("idObra: ", obra.IDObra);

    let inicio = new Date();
    console.log("INI: ", inicio);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_OBRAS_CON_CANTIDAD_DE_TAREAS",
        idObra: obra.IDObra,
        idUsuario: usuario.IDUsuario,
      }),
    });
    const respuesta = await datos.json();

    let fin = new Date();
    console.log("FIN: ", fin);

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      // setMensaje({ text: "Plano agregado con éxito!", tipo: "success" });
      // setMostrarMensaje(true);

      console.log("listaDeTareas: ", respuesta.result.listaDeObras);

      setObrasConCantTareas(respuesta.result.listaDeObras);
    } else {
      console.log("NO TRAE NADA EN traer_obras_con_cantidad_de_tareas()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const seleccionarObra = (obra) => {
    console.log("obra: ", obra);
    setObraElegida(obra);
  };

  return (
    <Grid container columns={{ xs: 12 }} border={0}>
      {mostrarProgress ? (
        <Grid item xs={12} border={0}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <FormControl sx={{ pl: 1 }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue="female"
            name="radio-buttons-group"
          >
            {obrasConCantTareas.map((obra, index) => (
              // <div key={item.IDProveedor}>
              <FormControlLabel
                value={obra.IDObra}
                key={obra.IDObra}
                control={
                  <Radio
                    // checked={index === 0}
                    icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                    checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                    onChange={() => seleccionarObra(obra)}
                  />
                }
                label={
                  obraElegida.IDObra === obra.IDObra ? (
                    <strong>{`${obra.Obra.toUpperCase()} (${
                      obra.CantidadDeTareas
                    })`}</strong>
                  ) : (
                    `${obra.Obra} (${obra.CantidadDeTareas})`
                  )
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </Grid>
  );
};

export default CheckObrasConCantidadDeTareas;
