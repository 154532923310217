import React, { useState } from "react";
import { Box, Typography, TextField } from "@mui/material";

const SelectedTaskComponent = ({ selectedTask, calculos, cantDias }) => {
  // console.log("selected: ", selectedTask);
  // console.log("calculos: ", calculos);

  console.log("cantDias: ", cantDias);

  // Estado para manejar la cantidad de recursos
  const [planificacion, setPlanificacion] = useState(
    selectedTask.Planificacion
  );
  const [cantidadDias, setCantidadDias] = useState(cantDias);

  // Función para encontrar el índice del recurso con el mayor consumo
  const encontrarIndiceMayorConsumo = (planificacion) => {
    // console.log("plani: ", planificacion);
    let indiceMayorConsumo = 0;
    let mayorConsumo = parseFloat(planificacion[0].Consumo);

    planificacion.forEach((recurso, index) => {
      const consumoActual = parseFloat(recurso.Consumo);
      if (consumoActual > mayorConsumo) {
        mayorConsumo = consumoActual;
        indiceMayorConsumo = index;
      }
    });

    // console.log("may: ", indiceMayorConsumo);

    return indiceMayorConsumo;
  };

  // Función para manejar el cambio en la cantidad de recursos
  const handleCantidadChange = (index, newValue) => {
    console.log("INDEX: ", index);
    console.log("newValue: ", newValue);
    const newPlanificacion = [...planificacion];
    newPlanificacion[index].CantidadRecursos = newValue;

    // Encontrar el índice del recurso con el mayor consumo
    const indiceMayorConsumo = encontrarIndiceMayorConsumo(newPlanificacion);

    console.log("indice: ", indiceMayorConsumo);

    // Actualizar cantidadDias solo si el recurso modificado es el de mayor consumo
    if (index === indiceMayorConsumo) {
      let dias = parseFloat(cantDias / newValue).toFixed(0);
      setCantidadDias(dias);

      // newPlanificacion[index].CantDias = dias * 8;
    }

    setPlanificacion(newPlanificacion);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {planificacion.map((recurso, index) => (
        <Box
          key={recurso.IDTareaRecurso} // Usar un ID único como key
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          <TextField
            type="number" // Solo permite números
            value={
              recurso.CantidadRecursos === "0" ? 1 : recurso.CantidadRecursos
            }
            onChange={(e) => handleCantidadChange(index, e.target.value)}
            inputProps={{ min: 0, style: { fontSize: "1.25rem" } }} // Tamaño de fuente más grande
            variant="standard" // Variante standard
            sx={{ width: "100px" }} // Ancho fijo para el campo de texto
          />

          <Typography variant="subtitle1" sx={{ fontSize: "1.25rem" }}>
            {recurso.Recurso} (
            {recurso.Consumo > 0 ? recurso.Consumo : recurso.Consumo.toFixed(0)}{" "}
            {recurso.UnidadComercial}/{recurso.TareaUnidad})
          </Typography>
        </Box>
      ))}
      <br />
      <Typography variant="subtitle1" sx={{ fontSize: "1.25rem" }}>
        {planificacion[0]?.TareaUnidad} pendientes:{" "}
        {parseFloat(planificacion[0]?.CuantificacionRestante).toFixed(0)}
      </Typography>

      <Typography variant="subtitle1" sx={{ fontSize: "1.25rem" }}>
        Tiempo estimado: {cantidadDias} días
      </Typography>
    </Box>
  );
};

export default SelectedTaskComponent;
